import { MAX_CELL_VALUE_CHARS } from '../../common/constants';
import { ObjectType } from '../../common/enums';
import { CellObjectValue, Contact, ContactObjectValue } from '../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { disableFreeTextNewOptions } from '../../common/utils/DisableFreeTextNewOptions';
import { isDisabledContactPickerOnDemandSelector } from '../../containers/App/Selectors';
import ContactPicker from '../ContactPicker';
import { checkIfOnDemandOptionsShouldBeEnabled } from '../ContactPicker/CheckIfOnDemandOptionsShouldBeEnabled';
import TextNumber from '../TextNumber';

interface Props {
    inputIndex: number;
    options?: Contact[];
    value?: any;
    readOnly?: boolean;
    placeholder?: string;
    validation?: boolean;
    onChange?: (e: any | string) => void;
    onClearContent?: () => void;
    isSettingsMode: boolean;
    onGetMultiContactsIfValid?: (
        initialValue: CellObjectValue,
        name?: string,
        substituteCurrentUser?: boolean,
        contacts?: Contact[]
    ) => Contact[] | string | null;
}

/**
 * When the column type is multi contact, still check for an object value as single, and multi-assign
 * columns have been merged together if the view source is a report.
 *
 * Check that the field either has a default value, or a value (IncludedFormField <--> DetailsData)
 * If the field has a value (either defaultValue, or actual value), begin mapping the values
 */
const FormFieldMultiContactList = ({
    inputIndex,
    value,
    validation,
    options,
    placeholder,
    readOnly,
    onChange,
    onClearContent,
    isSettingsMode,
    onGetMultiContactsIfValid,
}: Props) => {
    const handleChangeMultiContact = (contacts: ContactObjectValue[]): void => {
        const objectValue =
            contacts.length > 0
                ? {
                      objectType: ObjectType.MULTI_CONTACT,
                      values: contacts,
                  }
                : null;
        if (onChange) {
            onChange(objectValue);
        }
    };
    /**
     * If current user is one of the defaultValues, add current user to contactOptions
     * check if current user is already in contacts to avoid adding twice
     */
    // If initialValue is a string email, create array of 1 Contact object from it (otherwise return the string )
    // If initialValue is an objectValue, return contact array from objectValue.values

    const multiContactSelectedValue: undefined | string | ContactObjectValue[] = isSettingsMode
        ? value
        : onGetMultiContactsIfValid!(value as CellObjectValue, undefined, false);

    const notRestrictedToContactPickerOptions = !validation;
    const isDisabledContactPickerOnDemand = useSelector(isDisabledContactPickerOnDemandSelector);

    const multiEnableOnDemandOptions =
        notRestrictedToContactPickerOptions && !isDisabledContactPickerOnDemand && checkIfOnDemandOptionsShouldBeEnabled(options);
    // If value passed in is null or formattedMultiContact is valid, display contact picker
    if (multiContactSelectedValue == null || Array.isArray(multiContactSelectedValue)) {
        return (
            <ContactPicker
                key={`mcp-${inputIndex}`}
                dataClientId={AutomationIds.FIELD_FIELD_MULTI_CONTACT_LIST}
                allOptions={options}
                selectedOptions={multiContactSelectedValue}
                placeholder={placeholder}
                validateExistingSelections={validation}
                isValidNewOption={validation ? disableFreeTextNewOptions : undefined}
                enableOnDemandOptions={multiEnableOnDemandOptions}
                allowClearing={true}
                onChange={handleChangeMultiContact}
                disabled={readOnly}
                maxLength={MAX_CELL_VALUE_CHARS}
                multi={true}
                inPlace={true}
            />
        );
    } else {
        // Otherwise display non-contact info as TEXT_NUMBER field
        return (
            <TextNumber
                key={`tni-${inputIndex}`}
                dataClientId={AutomationIds.FORM_FIELD_WRAPPER_TEXT}
                value={multiContactSelectedValue as string}
                disabled={true}
                maxLength={MAX_CELL_VALUE_CHARS}
                className="clearable-content"
                clearable={!readOnly}
                onClearContent={onClearContent}
            />
        );
    }
};

export default FormFieldMultiContactList;
