/* eslint @typescript-eslint/no-misused-promises:"off" */
import { TransferResult } from '../../common/classes';
import { AsyncStatus, ErrorType, OwnershipTransferStatus } from '../../common/enums';
import * as dv from '../../common/interfaces';
import { IOwnershipTransfer } from '../../common/interfaces';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { OwnershipTransferContentType } from '../../common/enums/OwnershipTransferContentType.enum';
import { OwnershipTransferModalType } from '../../common/enums/OwnershipTransferModalType';
import { OwnershipTransferTabOption } from '../../common/enums/OwnershipTransferTabOption';
import { UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { mapUsersToContacts } from '../../common/utils/MapUserToContact';
import { BaseComponent } from '../../components/Base';
import withSetAppActionInProgress, { WithSetAppActionInProgressProps } from '../../components/hoc/WithSetAppActionInProgress';
import withSetAppError, { WithSetAppErrorProps } from '../../components/hoc/WithSetAppError';
import LandingPage from '../../components/LandingPage';
import ownershipTransferClient from '../../http-clients/OwnershipTransfer.client';
import viewSourceAdminClient from '../../http-clients/ViewSourceAdmin.client';
import { LanguageElementsProp, withLanguageElementsHOC } from '../../language-elements/withLanguageElementsHOC';
import { ActionByType, IAsyncResult, StoreState } from '../../store';
import { userEmailSelector, isUserSystemAdminAndLicensed } from '../Auth/Selectors';
import * as Home from '../Home/Actions';
import { Actions } from './Actions';
import OwnershipTransfer, { TransfersByTab } from './OwnershipTransfer';
import './OwnershipTransfer.css';
import { allUsersSelector, orgViewsSelector, pendingOwnershipTransfersSelector } from './Selectors';

interface DispatchProps {
    setStatusForTransfer: (transferId: string, status: OwnershipTransferStatus) => Actions;
    fetchHomeData: () => ActionByType<Home.Actions, Home.ActionTypes.FETCH_HOME_DATA>;
    setStatusForOrgView: (viewId: string, status: OwnershipTransferStatus) => Actions;
}

interface StateProps<T> {
    pendingOwnershipTransfers: IAsyncResult<T[]>;
    userEmail: string;
    orgViews: IAsyncResult<T[]>;
    isUserSystemAdmin: boolean;
    allUsers: dv.IPaginatedResult<dv.SmartsheetUser>;
}

type OwnershipTransferProps = StateProps<dv.IOwnershipTransfer> &
    DispatchProps &
    WithSetAppActionInProgressProps &
    WithSetAppErrorProps &
    RouteComponentProps<any>;

interface OwnershipTransferState {
    contentType: OwnershipTransferContentType;
    activeTab: OwnershipTransferTabOption;
    showModalType: OwnershipTransferModalType;
    activeTransferId?: string;
    isAllUsers: boolean;
    transferModalOptions: dv.SmartsheetUser[];
    activeTransfer?: IOwnershipTransfer;
    newOwner?: dv.Contact;
    transferError?: string;
}

/**
 * Responsible for loading pending ownership transfers, and rendering 1+ child components to display a row
 * component for each pending transfer. Child component also handles updates for a given transfer.
 */
export class OwnershipTransferContainer extends BaseComponent<OwnershipTransferProps & LanguageElementsProp, OwnershipTransferState> {
    private static getModalType(errorCode: number | undefined, status: OwnershipTransferStatus): OwnershipTransferModalType {
        switch (errorCode) {
            case ErrorType.VIEW_NOT_FOUND:
                // If the view has been deleted, then show a specific error message to the user.
                return OwnershipTransferModalType.ERROR_VIEW_NOT_FOUND;

            case ErrorType.OWNERSHIP_TRANSFER_STATUS_NOT_PENDING:
                // If the original owner tries to cancel the transfer after the new owner has already
                // accepted or declined it, then show the following error message to the original owner.
                if (status === OwnershipTransferStatus.CANCELED) {
                    return OwnershipTransferModalType.ERROR_TRANSFER_WAS_ALREADY_ACCEPTED_OR_REJECTED_BY_NEW_OWNER;
                }

                // Otherwise, if the new owner tries to accept or decline the transfer after the original owner
                // has already canceled the transfer, then show the following error message to the new owner.
                return OwnershipTransferModalType.ERROR_TRANSFER_WAS_CANCELED;

            case ErrorType.OWNERSHIP_TRANSFER_NEW_OWNER_NO_DYNAMIC_VIEW_LICENSE:
                return OwnershipTransferModalType.NO_DYNAMIC_VIEW_LICENSE;

            default:
                // If we don't have a specific reason for the update failing, then show a generic error message to the user.
                return OwnershipTransferModalType.ERROR_ON_TRANSFER;
        }
    }

    public state: OwnershipTransferState;

    public constructor(props: OwnershipTransferProps & LanguageElementsProp) {
        super(props);

        this.state = {
            contentType: OwnershipTransferContentType.NONE,
            activeTab: OwnershipTransferTabOption.RECEIVED,
            showModalType: OwnershipTransferModalType.NONE,
            isAllUsers: false,
            transferModalOptions: [],
        };
    }

    public render(): React.ReactNode {
        if (this.state.contentType === OwnershipTransferContentType.ERROR) {
            return (
                <LandingPage
                    title={this.props.languageElements.OWNERSHIP_TRANSFER_ERROR_TITLE}
                    message={this.props.languageElements.OWNERSHIP_TRANSFER_ERROR_MESSAGE}
                />
            );
        }

        if (this.state.contentType === OwnershipTransferContentType.TRANSFERS) {
            const transfersByTab = this.getTransfersByTab(this.props.pendingOwnershipTransfers, this.props.orgViews);

            // Allow ownership transfer for admin if user is admin and licensed and the feature flag is enabled
            const isSysAdminOwnershipTransferAllowed = this.props.isUserSystemAdmin;
            return (
                <OwnershipTransfer
                    activeTab={this.state.activeTab}
                    transfersByTab={transfersByTab}
                    onClickTab={this.handleClickTab}
                    onKeyDownTab={this.handleKeyDownTab}
                    dataClientId={AutomationIds.OWNERSHIP_TRANSFER_TAB_DATA}
                    dataClientType={AutomationTypes.OWNERSHIP_TRANSFER_TAB_ITEM}
                    onAccept={this.handleAccept}
                    onDecline={this.handleDecline}
                    onCancel={this.handleCancel}
                    showModalType={this.state.showModalType}
                    onErrorMessageClose={this.handleErrorMessageClose}
                    isSysAdminOwnershipTransferAllowed={isSysAdminOwnershipTransferAllowed}
                    onTransferModalOpen={this.handleTransferModalOpen}
                    activeTransfer={this.state.activeTransfer}
                    onTransferModalClose={this.handleTransferModalClose}
                    isAllUsers={this.state.isAllUsers}
                    transferModalOptions={mapUsersToContacts(this.state.transferModalOptions)}
                    newOwner={this.state.newOwner}
                    onUserChange={this.handleOwnerChange}
                    onTransferOwnership={this.handleTransferOwnership}
                    transferError={this.state.transferError}
                />
            );
        }

        return null;
    }

    public componentDidUpdate(prevProps: OwnershipTransferProps): void {
        const pendingOwnershipTransfers = this.props.pendingOwnershipTransfers;
        const prevPendingOwnershipTransfers = prevProps.pendingOwnershipTransfers;

        // Just return if FETCHING status is unchanged from previous props
        if (prevPendingOwnershipTransfers && prevPendingOwnershipTransfers.status === pendingOwnershipTransfers.status) {
            return;
        }

        // Handles re-render when fetching is in progress
        switch (pendingOwnershipTransfers.status) {
            case AsyncStatus.NOT_STARTED:
                if (this.state.contentType !== OwnershipTransferContentType.NONE) {
                    this.setState(
                        {
                            contentType: OwnershipTransferContentType.NONE,
                        },
                        this.props.onResetAppStage
                    );
                }
                break;

            case AsyncStatus.IN_PROGRESS:
                if (this.state.contentType !== OwnershipTransferContentType.NONE) {
                    this.setState({ contentType: OwnershipTransferContentType.NONE });
                }
                break;

            case AsyncStatus.DONE:
                if (this.state.contentType !== OwnershipTransferContentType.TRANSFERS) {
                    this.setState(
                        {
                            contentType: OwnershipTransferContentType.TRANSFERS,
                        },
                        this.props.onResetAppStage
                    );
                }
                break;

            case AsyncStatus.ERROR:
                if (this.state.contentType !== OwnershipTransferContentType.ERROR) {
                    this.setState(
                        {
                            contentType: OwnershipTransferContentType.ERROR,
                        },
                        this.props.onResetAppStage
                    );
                }
                break;
        }
    }

    public handleKeyDownTab = (e: React.KeyboardEvent, tab: OwnershipTransferTabOption): void => {
        // If user navigates via keyboard to another tab, pressing Enter or Space bar should activate that tab
        if (e.key && (e.key === 'Enter' || e.key === ' ')) {
            this.activateTab(tab);
        }
    };

    public handleClickTab = (tab: OwnershipTransferTabOption): void => {
        this.activateTab(tab);
    };

    private activateTab = (tab: OwnershipTransferTabOption): void => {
        this.setState({
            activeTab: tab,
        });
    };

    private getTransfersByTab = (
        pendingOwnershipTransfers: IAsyncResult<IOwnershipTransfer[]>,
        orgViews: IAsyncResult<IOwnershipTransfer[]>
    ): TransfersByTab => {
        if (pendingOwnershipTransfers.status !== AsyncStatus.DONE && orgViews.status !== AsyncStatus.DONE) {
            return { sent: [], received: [], orgViews: [] };
        }

        const pendingTransferViewIds = new Set<string>();
        pendingOwnershipTransfers.data!.forEach((transfer) => pendingTransferViewIds.add(transfer.view.id));
        const orgViewsWithoutPendingTransfers = orgViews.data!.filter((orgView) =>
            orgView.view.id ? !pendingTransferViewIds.has(orgView.view.id) : true
        );
        orgViewsWithoutPendingTransfers.sort((a, b) => {
            const emailA = a.currentOwner.email.toLowerCase();
            const emailB = b.currentOwner.email.toLowerCase();
            if (emailA === emailB) {
                return 0;
            }

            if (emailA < emailB) {
                return -1;
            }
            return 1;
        });

        return {
            received: pendingOwnershipTransfers.data!.filter((transfer) => transfer.newOwner.email.toLowerCase() === this.props.userEmail),
            sent: pendingOwnershipTransfers.data!.filter((transfer) => transfer.currentOwner.email.toLowerCase() === this.props.userEmail),
            orgViews: orgViewsWithoutPendingTransfers,
        };
    };

    private handleAccept = async (transferId: string): Promise<void> => {
        await this.updateTransfer(
            'acceptedOwnershipTransfer',
            transferId,
            OwnershipTransferStatus.ACCEPT_IN_PROGRESS,
            OwnershipTransferStatus.ACCEPTED,
            (transferResult) => {
                // Update home view list
                this.props.fetchHomeData();

                // Needed to display filter warning message to new owner after accept is complete
                // when the invalid or private sheet filter is applied to the view
                const sheetFilterValidationResult = transferResult.validationResults.sheetFilters;
                if (sheetFilterValidationResult.isPrivate || sheetFilterValidationResult.isDeleted) {
                    this.setState({ showModalType: OwnershipTransferModalType.WARN_INVALID_FILTER });
                }
            }
        );
    };

    private handleDecline = async (transferId: string): Promise<void> => {
        await this.updateTransfer(
            'declinedOwnershipTransfer',
            transferId,
            OwnershipTransferStatus.REJECT_IN_PROGRESS,
            OwnershipTransferStatus.REJECTED
        );
    };

    private handleCancel = async (transferId: string): Promise<void> => {
        await this.updateTransfer(
            'canceledOwnershipTransfer',
            transferId,
            OwnershipTransferStatus.CANCEL_IN_PROGRESS,
            OwnershipTransferStatus.CANCELED
        );
    };

    private updateTransfer = async (
        name: string,
        transferId: string,
        pendingStatus: OwnershipTransferStatus,
        completedStatus: OwnershipTransferStatus,
        onComplete?: (transferResult: TransferResult) => void
    ): Promise<void> => {
        this.setState({ activeTransferId: transferId });

        // While updating the transfer status in the db, set the status in the Redux store to the pending state.
        this.props.setStatusForTransfer(transferId, pendingStatus);

        try {
            await UserAnalyticsAction.recordAsyncDuration<void>(name, async () => {
                const transferResult = await ownershipTransferClient.update(transferId, completedStatus);

                // Update the status on the transfer to it's resolved status in the Redux store
                this.props.setStatusForTransfer(transferId, completedStatus);

                // Call the onComplete callback if one has been passed in.
                if (onComplete) {
                    onComplete(transferResult);
                }
            });
        } catch (error) {
            // Revert the status on the transfer to PENDING in the Redux store
            this.props.setStatusForTransfer(transferId, OwnershipTransferStatus.PENDING);

            // Show a different error message depending on what failed.
            const errorCode = error.response && error.response.data && error.response.data.errorCode;
            const showModalType = OwnershipTransferContainer.getModalType(errorCode, completedStatus);
            this.setState({ showModalType });
        }
    };

    private handleErrorMessageClose = (): void => {
        this.setState({ showModalType: OwnershipTransferModalType.NONE }); // Close modal
    };

    private handleTransferModalOpen = async (transfer: IOwnershipTransfer) => {
        const viewId = transfer.view.id;
        let transferResponse;
        try {
            transferResponse = await ownershipTransferClient.get(OwnershipTransferStatus.PENDING, viewId);
        } catch (error) {
            const errorCode = error.response && error.response.data && error.response.data.errorCode;
            const showModalType = OwnershipTransferContainer.getModalType(errorCode, transfer.status);
            this.setState({ showModalType });
            return;
        }

        const activeTransfer = transferResponse && transferResponse.data.length > 0 ? transferResponse.data[0] : transfer;

        // If we fail to fetch the view source admins for the view (this can happen when the current owner has left the company and their token no
        // longer works), then we will show all users from the current org in the transferModalOptions instead.
        try {
            const adminsResponse = await viewSourceAdminClient.getViewSourceAdmins(viewId);
            const adminsWithoutOwners = adminsResponse.data.filter((admin) => {
                return !(
                    admin.email.toLowerCase() === activeTransfer.currentOwner.email.toLowerCase() ||
                    admin.email.toLowerCase() === activeTransfer.newOwner.email.toLowerCase()
                );
            });
            this.setState({ activeTransfer, isAllUsers: false, transferModalOptions: adminsWithoutOwners });
        } catch {
            const allUsersWithoutOwner = this.props.allUsers.data.filter((user) => {
                return !(
                    user.email.toLowerCase() === activeTransfer.currentOwner.email.toLowerCase() ||
                    user.email.toLowerCase() === activeTransfer.newOwner.email.toLowerCase()
                );
            });
            this.setState({ activeTransfer, isAllUsers: true, transferModalOptions: allUsersWithoutOwner });
        }
    };

    private handleTransferModalClose = () => {
        this.setState({ activeTransfer: undefined, newOwner: undefined, transferError: undefined, transferModalOptions: [] });
    };

    private handleOwnerChange = (owner: dv.Contact) => {
        this.setState({ newOwner: owner, transferError: undefined });
    };

    private handleTransferOwnership = async () => {
        const { view, status, id } = this.state.activeTransfer!;
        try {
            if (view.id && this.state.newOwner && this.state.newOwner.email) {
                if (status === OwnershipTransferStatus.PENDING) {
                    await ownershipTransferClient.update(id, OwnershipTransferStatus.CANCELED);
                }
                await ownershipTransferClient.create(view.id, this.state.newOwner.email);
                this.props.setStatusForOrgView(view.id, OwnershipTransferStatus.PENDING);
            }
            this.handleTransferModalClose();
        } catch (error) {
            const errorCode = error.response && error.response.data && error.response.data.errorCode;
            if (errorCode === ErrorType.OWNERSHIP_TRANSFER_NOT_ADMIN_OF_VIEW_SOURCE) {
                this.setState({ transferError: this.props.languageElements.OWNERSHIP_TRANSFER_NOT_ADMIN_OF_VIEW_SOURCE });
            } else {
                this.setState({ transferError: this.props.languageElements.OWNERSHIP_TRANSFER_TRY_AGAIN_MESSAGE });
            }
        }
    };
}

const mapState = createStructuredSelector<StoreState, any>({
    pendingOwnershipTransfers: pendingOwnershipTransfersSelector,
    userEmail: userEmailSelector,
    orgViews: orgViewsSelector,
    isUserSystemAdmin: isUserSystemAdminAndLicensed,
    allUsers: allUsersSelector,
});

const mapDispatch: DispatchProps = {
    setStatusForTransfer: Actions.setStatusForTransfer,
    fetchHomeData: Home.Actions.fetchHomeData,
    setStatusForOrgView: Actions.setStatusForOrgView,
};

export default withLanguageElementsHOC(
    withRouter(
        withSetAppActionInProgress(
            withSetAppError(connect<StateProps<dv.IOwnershipTransfer>, DispatchProps>(mapState, mapDispatch)(OwnershipTransferContainer))
        )
    )
);
