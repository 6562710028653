import { AxiosPromise } from 'axios';
import axiosInstance from './Axios.instance';

export const logOutDv = async (): AxiosPromise<void> => {
    return axiosInstance.post(`/auth/logout`, {});
};

export const logOutSmar = async (): AxiosPromise<void> => {
    return axiosInstance.post(`/b/home?formName=ajax&formAction=fa_signOut`, {}, { baseURL: '' });
};
