import * as React from 'react';
import { TableRowProps } from 'react-virtualized';
import { GridMouseEvent, GridRow } from '../Grid.interface';

interface Props extends TableRowProps {
    key: string;
    onRowClick: (event: GridMouseEvent) => void;
    onRowDoubleClick: (event: GridMouseEvent) => void;
    onRowMouseOut: (event: GridMouseEvent) => void;
    onRowMouseOver: (event: GridMouseEvent) => void;
    onRowRightClick: (event: GridMouseEvent) => void;
    rowData: GridRow;
    style: object;
}

interface RowProps {
    'aria-label': string;
    tabIndex: number;
    onClick?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onDoubleClick?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onMouseOut?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onMouseOver?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
    onContextMenu?: (mouseEvent: React.MouseEvent<HTMLDivElement>) => void;
}

const ControlId = {
    VIEW_ROW_DATA: 'rrd-1',
};

const GridRowElement: React.FC<Props> = (props) => {
    const rowProps: RowProps = { 'aria-label': 'row', tabIndex: 0 };

    if (
        // rowData.id empty string is a special case for the not loaded rows to display the skeleton,
        // we set id to empty string to prevent the focus on the not loaded rows in Grid -> getRowData
        props.rowData.id !== '' &&
        (props.onRowClick || props.onRowDoubleClick || props.onRowMouseOut || props.onRowMouseOver || props.onRowRightClick)
    ) {
        if (props.onRowClick) {
            rowProps.onClick = (mouseEvent: React.MouseEvent<HTMLDivElement>) =>
                props.onRowClick({ event: mouseEvent, rowData: props.rowData, index: props.index });
        }
        if (props.onRowDoubleClick) {
            rowProps.onDoubleClick = (mouseEvent: React.MouseEvent<HTMLDivElement>): void =>
                props.onRowDoubleClick({ event: mouseEvent, rowData: props.rowData, index: props.index });
        }
        if (props.onRowMouseOut) {
            rowProps.onMouseOut = (mouseEvent: React.MouseEvent<HTMLDivElement>): void =>
                props.onRowMouseOut({ event: mouseEvent, rowData: props.rowData, index: props.index });
        }
        if (props.onRowMouseOver) {
            rowProps.onMouseOver = (mouseEvent: React.MouseEvent<HTMLDivElement>): void =>
                props.onRowMouseOver({ event: mouseEvent, rowData: props.rowData, index: props.index });
        }
        if (props.onRowRightClick) {
            rowProps.onContextMenu = (mouseEvent: React.MouseEvent<HTMLDivElement>): void =>
                props.onRowRightClick({ event: mouseEvent, rowData: props.rowData, index: props.index });
        }
    }

    return (
        <div {...rowProps} className={props.className} key={props.key} role="row" style={props.style} data-client-type={ControlId.VIEW_ROW_DATA}>
            {props.columns}
        </div>
    );
};

export default GridRowElement;
