import { AxiosError } from 'axios';
import { retryBackoff } from 'backoff-rxjs';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { defer, from, Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AsyncStatus } from '../../../common/enums';
import { ReportJobStatusResponse, ReportStatus } from '../../../common/interfaces/report';
import { transformReportRowsToGridRows } from '../../../components/Grid/GridDataTransformer';
import * as reportClient from '../../../http-clients/ReportClient';
import { ActionByType, backoffDelayFunction, StoreState, logAndRecordFailureMetrics, logAndRecordSuccessMetrics } from '../../../store';
import { userLocaleSelector } from '../../Auth/Selectors';
import { Actions, ActionTypes } from './Actions';
import { reportColumnsSelector, reportJobSelector } from './Selectors';

const FILENAME = 'Report/Epic.ts';
const POLLING_DELAY = 1000;
const POLLING_INTERVAL_MS = 500;
const POLLING_MAX_INTERVAL_MS = 120000;
const POLLING_MAX_RETRIES = 20;
export const PAGINATION_LIMIT = 1000;

/**
 * Epic to initiate the load a report job. Depending on the status of the report job, it will either call the action to fetch the first page of report
 * data or poll the report job until it is ready.
 *
 * @param {Observable<Actions>} action$ - Stream of actions.
 * @returns {Observable<Actions>} - Stream of resulting actions.
 */
export const loadReportJobEpic: Epic<Actions> = (action$: Observable<Actions>): Observable<Actions> => {
    return action$.pipe(
        ofType(ActionTypes.LOAD_REPORT_JOB),
        switchMap((action: ActionByType<Actions, ActionTypes.LOAD_REPORT_JOB>) => {
            const startTime = Date.now(); // Record the start time

            const { viewId } = action.payload;
            return from(reportClient.reportJob(action.payload)).pipe(
                mergeMap((response) => {
                    const actions: Actions[] = [Actions.storeReportJob(response)];

                    if (response.statuses.reportStatus === ReportStatus.READY) {
                        // The report is ready, so no need to poll, instead call the action to fetch the report data
                        actions.push(Actions.fetchReportData({ viewId, page: 1 }));
                    } else {
                        // The report is not ready, this could be due to the complexity of the report, so polling is required
                        actions.push(Actions.pollReportJob(viewId));
                    }

                    return actions;
                }),
                takeUntil(action$.pipe(ofType(ActionTypes.CANCEL_LOAD_REPORT_JOB))),
                tap(() => {
                    logAndRecordSuccessMetrics({
                        fileName: FILENAME,
                        funcName: 'loadReportJobEpic',
                        message: 'Load report job',
                        startTime,
                        additionalInfo: { viewId },
                    });
                }),
                catchError((error: AxiosError) => {
                    logAndRecordFailureMetrics({ fileName: FILENAME, funcName: 'loadReportJobEpic', startTime, error, additionalInfo: { viewId } });

                    return of(Actions.fetchingErrorReportJob(error));
                })
            );
        })
    );
};

/**
 * Polls the report job status until it is ready.
 *
 * @param {string} viewId - The ID of the view for which the report job status is being polled.
 * @returns {Observable<ReportJobStatusResponse>} - An observable that emits the report job status.
 */
const pollingReportJob = (viewId: string): Observable<ReportJobStatusResponse> => {
    return timer(POLLING_DELAY).pipe(
        switchMap(() => {
            return defer(() => from(reportClient.getReportJobStatus(viewId))).pipe(
                map((response) => {
                    if (response.statuses.reportStatus !== ReportStatus.READY) {
                        // If the report is not ready, throw an error to retry the polling
                        throw new Error(response.statuses.reportStatus);
                    }

                    return response;
                }),
                retryBackoff({
                    initialInterval: POLLING_INTERVAL_MS,
                    maxInterval: POLLING_MAX_INTERVAL_MS,
                    backoffDelay: backoffDelayFunction,
                    resetOnSuccess: true,
                    maxRetries: POLLING_MAX_RETRIES,
                    shouldRetry: (error) => {
                        return error?.message === ReportStatus.PENDING || error?.message === ReportStatus.IN_PROGRESS;
                    },
                })
            );
        })
    );
};

/**
 * Epic to poll the report job status and call the action to fetch the first page of report data when the report job is ready.
 *
 * @param {Observable<Actions>} action$ - Stream of actions.
 * @returns {Observable<Actions>} - Stream of resulting actions.
 */
export const pollReportJobEpic: Epic<Actions> = (action$: Observable<Actions>): Observable<Actions> => {
    return action$.pipe(
        ofType(ActionTypes.POLL_REPORT_JOB),
        switchMap((action: ActionByType<Actions, ActionTypes.POLL_REPORT_JOB>) => {
            const startTime = Date.now(); // Record the start time

            const { viewId } = action.payload;

            return pollingReportJob(viewId).pipe(
                map(() => {
                    return Actions.fetchReportData({ viewId, page: 1 });
                }),
                takeUntil(action$.pipe(ofType(ActionTypes.CANCEL_POLL_REPORT_JOB))),
                tap(() => {
                    logAndRecordSuccessMetrics({
                        fileName: FILENAME,
                        funcName: 'pollReportJobEpic',
                        message: 'Poll report job',
                        startTime,
                        additionalInfo: { viewId },
                    });
                }),
                catchError((error: AxiosError) => {
                    logAndRecordFailureMetrics({
                        fileName: FILENAME,
                        funcName: 'pollReportJobEpic',
                        startTime,
                        error,
                        additionalInfo: { viewId },
                    });

                    return of(Actions.fetchingErrorReportData(error));
                })
            );
        })
    );
};

/**
 * Epic to fetch report data.
 *
 * @param {Observable<Actions>} action$ - Stream of actions.
 * @param {StateObservable<StoreState>} state$ - Stream of state.
 * @returns {Observable<Actions>} - Stream of resulting actions.
 */
export const fetchReportDataEpic: Epic<Actions> = (action$: Observable<Actions>, state$: StateObservable<StoreState>): Observable<Actions> => {
    return action$.pipe(
        ofType(ActionTypes.FETCH_REPORT_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_REPORT_DATA>) => {
            const startTime = Date.now(); // Record the start time

            const { viewId, page, pagination = { limit: PAGINATION_LIMIT }, filter, timestamp } = action.payload;
            const reportJobResult = reportJobSelector(state$.value);

            if (reportJobResult.status !== AsyncStatus.DONE) {
                return of(Actions.fetchingErrorReportData(new Error('Report job not complete')));
            }

            return from(
                reportClient.getReport({
                    viewId,
                    pagination,
                    jobId: reportJobResult.data.jobId,
                    filter,
                    timestamp,
                })
            ).pipe(
                map((response) => {
                    const reportColumnsResult = reportColumnsSelector(state$.value);

                    if (reportColumnsResult.status !== AsyncStatus.DONE) {
                        return Actions.fetchingErrorReportData(new Error('Report columns not complete'));
                    }

                    const userLocale = userLocaleSelector(state$.value);
                    const transformedRows = transformReportRowsToGridRows({
                        rows: response.rows,
                        reportColumns: reportColumnsResult.data,
                        userLocale,
                    });
                    return Actions.storeReportData({ rows: transformedRows, pagination: response.pagination });
                }),
                takeUntil(action$.pipe(ofType(ActionTypes.CANCEL_FETCH_REPORT_DATA))),
                tap(() => {
                    logAndRecordSuccessMetrics({
                        fileName: FILENAME,
                        funcName: 'fetchReportDataEpic',
                        message: 'Fetch report data',
                        startTime,
                        additionalInfo: { viewId, page },
                    });
                }),
                catchError((error: AxiosError) => {
                    logAndRecordFailureMetrics({ fileName: FILENAME, funcName: 'fetchReportDataEpic', startTime, error, additionalInfo: { viewId } });

                    return of(Actions.fetchingErrorReportData(error));
                })
            );
        })
    );
};

/**
 * Epic to fetch report columns.
 *
 * @param {Observable<Actions>} action$ - Stream of actions.
 * @returns {Observable<Actions>} - Stream of resulting actions.
 */
export const fetchReportColumnsEpic: Epic<Actions> = (action$: Observable<Actions>): Observable<Actions> => {
    return action$.pipe(
        ofType(ActionTypes.FETCH_REPORT_COLUMNS),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_REPORT_COLUMNS>) => {
            const startTime = Date.now(); // Record the start time
            const viewId = action.payload;

            return from(reportClient.getColumns(viewId)).pipe(
                map((response) => {
                    return Actions.storeReportColumns(response.reportColumns);
                }),
                takeUntil(action$.pipe(ofType(ActionTypes.CANCEL_FETCH_REPORT_COLUMNS))),
                tap(() => {
                    logAndRecordSuccessMetrics({
                        fileName: FILENAME,
                        funcName: 'fetchReportColumnsEpic',
                        message: 'Fetch report columns',
                        startTime,
                        additionalInfo: { viewId },
                    });
                }),
                catchError((error: AxiosError) => {
                    logAndRecordFailureMetrics({
                        fileName: FILENAME,
                        funcName: 'fetchReportColumnsEpic',
                        startTime,
                        error,
                        additionalInfo: { viewId },
                    });

                    return of(Actions.fetchingErrorReportColumns(error));
                })
            );
        })
    );
};

export const resetReportEpic: Epic<Actions> = (action$: Observable<Actions>): Observable<Actions> => {
    return action$.pipe(
        ofType(ActionTypes.RESET_REPORT_STATE),
        mergeMap(() => {
            return [
                Actions.cancelLoadReportJob(),
                Actions.cancelPollReportJob(),
                Actions.cancelFetchReportData(),
                Actions.cancelFetchReportColumns(),
            ];
        })
    );
};
