import { QueryStringKey } from '../common/enums/QueryStringKey.enum';
import { IPaginatedResult, SmartsheetUser } from '../common/interfaces';
import { buildUrl } from '../common/utils';
import { User } from '../containers/Auth/types';
import axiosInstance from './Axios.instance';
import { AxiosFactory } from './AxiosFactory';

class UsersClient {
    public static getInstance(): UsersClient {
        if (!UsersClient.instance) {
            UsersClient.instance = new UsersClient();
        }
        return UsersClient.instance;
    }

    private static instance: UsersClient;
    private static url = '/users';

    private constructor() {}

    public async queryUsers(query: string = ''): Promise<IPaginatedResult<SmartsheetUser>> {
        const url = buildUrl(UsersClient.url, {
            queryParams: {
                [QueryStringKey.QUERY]: query,
            },
        });
        const response = await axiosInstance.get(url);
        return response.data;
    }

    public async getUsers(disableContactPickerOnDemand: boolean = false, page: number = 1): Promise<IPaginatedResult<SmartsheetUser>> {
        let url;
        if (disableContactPickerOnDemand) {
            url = buildUrl(UsersClient.url);
        } else {
            url = buildUrl(UsersClient.url, {
                queryParams: {
                    [QueryStringKey.PAGE]: page,
                },
            });
        }

        const response = await axiosInstance.get(url);
        return response.data;
    }

    public async getCurrentUser(): Promise<User> {
        // This method needs to use it's own instance of the axios client.
        // The globally configured one will catch the 401 response and reload the page in the browser.
        // In this method, we want to ignore the 401 response and let the caller handled it.
        const localAxiosInstance = AxiosFactory.create();

        const response = await localAxiosInstance.get(`${UsersClient.url}/me`);
        if (response.headers['x-smar-xsrf']) {
            window.sessionStorage.setItem('x-smar-xsrf', response.headers['x-smar-xsrf']);
        }
        // TODO [DVK-0469]: Why are we returning User here instead of SmartsheetUser? 😿
        return response.data;
    }

    public async getUsersByEmail(emails: string[]): Promise<IPaginatedResult<SmartsheetUser>> {
        const data = { emails };
        const response = await axiosInstance.post(`internal/getusers`, data);
        return response.data;
    }

    public async getAdminEmail(): Promise<string> {
        const response = await axiosInstance.get(`organization/adminemail`);
        return response.data;
    }
}
const usersClient = UsersClient.getInstance();
export default usersClient;
