import { combineEpics } from 'redux-observable';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concat } from 'rxjs/operators';
import { HttpStatusCodes } from '../common/enums';
import {
    createOwnershipTransferEpic,
    fetchAdminViewDataEpic,
    fetchAllAdminDataEpic,
    fetchSmartsheetAdminsEpic,
    fetchSmartsheetGroupsEpic,
    fetchSmartsheetUsersByEmailsEpic,
    fetchSmartsheetUsersEpic,
    fetchViewSourceMetaDataEpic,
    updateAdminViewDataEpic,
    updateSharesEpic,
} from '../containers/Admin/Epic';
import { Actions } from '../containers/App/Actions';
import { fetchFeatureFlagsEpic } from '../containers/App/Epic';
import { fetchHomeDataEpic } from '../containers/Home';
import { fetchingOwnershipTransfersEpic, fetchOrgViewsEpic } from '../containers/OwnershipTransfer/Epic';
import {
    insertCellImagesEpic,
    insertViewRowEpic,
    updateViewRowEpic,
    upsertViewRowFailureEpic,
    upsertViewRowInProgressEpic,
    upsertViewRowSuccessEpic,
} from '../containers/View/Details/Epic';
import {
    fetchConfigEpic,
    fetchContainerLinksEpic,
    fetchGridRowEpic,
    fetchViewDataAllEpic,
    fetchViewDataEpic,
    fetchViewEligibilityEpic,
    storeViewDataEpic,
} from '../containers/View/Epic';
import { fetchReportColumnsEpic, fetchReportDataEpic, loadReportJobEpic, pollReportJobEpic, resetReportEpic } from '../containers/View/Report/Epic';
import { fetchImageUrlsEpic } from './images/Epic';

const rootEpic = (action$: any, state$: any): Observable<any> =>
    combineEpics(
        fetchAdminViewDataEpic,
        fetchSmartsheetAdminsEpic,
        fetchSmartsheetUsersEpic,
        fetchSmartsheetGroupsEpic,
        fetchViewSourceMetaDataEpic,
        updateAdminViewDataEpic,
        updateSharesEpic,
        fetchingOwnershipTransfersEpic,
        fetchOrgViewsEpic,
        fetchAllAdminDataEpic,
        fetchSmartsheetUsersByEmailsEpic,
        createOwnershipTransferEpic,
        fetchFeatureFlagsEpic,
        updateViewRowEpic,
        insertViewRowEpic,
        upsertViewRowInProgressEpic,
        insertCellImagesEpic,
        upsertViewRowSuccessEpic,
        upsertViewRowFailureEpic,
        fetchImageUrlsEpic,
        fetchHomeDataEpic,
        fetchViewDataEpic,
        fetchViewDataAllEpic,
        fetchViewEligibilityEpic,
        fetchGridRowEpic,
        fetchConfigEpic,
        storeViewDataEpic,
        fetchContainerLinksEpic,
        loadReportJobEpic,
        pollReportJobEpic,
        fetchReportDataEpic,
        fetchReportColumnsEpic,
        resetReportEpic
    )(action$, state$, undefined).pipe(
        catchError((error, source) => {
            if (error && error.response) {
                // Unauthorized requests result in a redirect to the login screen. This is handled by
                // the AxiosInstance interceptor. Due to this an empty observable is returned
                if (error.response.status === HttpStatusCodes.UNAUTHORIZED) {
                    return EMPTY;
                }
            }

            // Dispatch action to store error and concat source which stops ALL epics from being
            // unsubscribed.
            return of(Actions.setAppStageError(error)).pipe(concat(source));
        })
    );

export default rootEpic;
