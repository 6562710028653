import { AxiosError } from 'axios';
import { AsyncStatus } from '../../../common/enums';
import { Pagination, ReportJobResponse } from '../../../common/interfaces/report';
import { GridRow } from '../../../components/Grid/Grid.interface';
import { AsyncResult } from '../../../store';
import { Actions, ActionTypes } from './Actions';
import { Column } from '@smartsheet/core-views-api-models';

export interface ReportState {
    reportJob: AsyncResult<ReportJobResponse, AxiosError | Error>;
    reportData: AsyncResult<{ rows: GridRow[]; pagination: Pagination }, AxiosError | Error>;
    columns: AsyncResult<Column[], AxiosError | Error>;
}

const initialState: ReportState = {
    reportJob: {
        status: AsyncStatus.NOT_STARTED,
    },
    reportData: {
        status: AsyncStatus.NOT_STARTED,
    },
    columns: {
        status: AsyncStatus.NOT_STARTED,
    },
};

const reducer = (state: ReportState = initialState, action: Actions): ReportState => {
    switch (action.type) {
        case ActionTypes.RESET_REPORT_STATE:
            return { ...initialState };

        // Report Job
        case ActionTypes.LOAD_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.STORE_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.DONE, data: action.payload } };

        case ActionTypes.FETCHING_ERROR_REPORT_JOB:
            return { ...state, reportJob: { status: AsyncStatus.ERROR, error: action.payload.error } };

        // Report Data
        case ActionTypes.FETCH_REPORT_DATA:
            if (state.reportData.status === AsyncStatus.DONE) {
                return { ...state, reportData: { status: AsyncStatus.PARTIAL, data: state.reportData.data } };
            } else {
                return { ...state, reportData: { status: AsyncStatus.IN_PROGRESS } };
            }
        case ActionTypes.STORE_REPORT_DATA:
            const { rows: rowsToAppend, pagination } = action.payload;

            let rows = rowsToAppend;

            if (state.reportData.status === AsyncStatus.PARTIAL) {
                // Append new page of rows to existing rows
                const existingRows = state.reportData.data.rows;
                rows = existingRows.concat(rows);
            }

            return { ...state, reportData: { status: AsyncStatus.DONE, data: { rows, pagination } } };

        case ActionTypes.FETCHING_ERROR_REPORT_DATA:
            return { ...state, reportData: { status: AsyncStatus.ERROR, error: action.payload.error } };

        // Report Columns
        case ActionTypes.FETCH_REPORT_COLUMNS:
            return { ...state, columns: { status: AsyncStatus.IN_PROGRESS } };

        case ActionTypes.STORE_REPORT_COLUMNS:
            return { ...state, columns: { status: AsyncStatus.DONE, data: action.payload } };

        case ActionTypes.FETCHING_ERROR_REPORT_COLUMNS:
            return { ...state, columns: { status: AsyncStatus.ERROR, error: action.payload.error } };
    }

    return state;
};

export default reducer;
