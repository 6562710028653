import { ColumnType, SymbolSetName } from '../enums';
import { Format, PicklistItem, SelectV2Option, MultiPicklistObjectValue } from '../interfaces';
import { isMultiPicklistObjectValue } from '../utils';
import { CheckboxOptions, SymbolImageUrlResolver } from '../../components/Picklist/SymbolPickerImages';
import * as gsFormattingUtility from './GsFormattingUtility';
import * as FormatTypes from '../enums/FormatTypes.enum';

export const getOptions = (
    type: string,
    symbolName: SymbolSetName | undefined,
    optionValues: string[] | undefined,
    format?: Format
): PicklistItem[] | undefined => {
    switch (type) {
        case ColumnType.PICKLIST: {
            const numberFormatObject = format ? gsFormattingUtility.getNumberFormatObjectAll(format, true) : undefined;
            const isPercentFormat = numberFormatObject?.numberFormat === FormatTypes.NumberFormatType.percent;
            return mapToPicklistOptions(optionValues, symbolName, isPercentFormat);
        }
        case ColumnType.MULTI_PICKLIST:
            return mapToMultiPicklistOptions(optionValues);
        case ColumnType.CHECKBOX:
            return getCheckboxOptions(symbolName);
    }
    return undefined;
};

const getOptionValueForSymbol = (option: string, symbolName: SymbolSetName) => {
    return `${symbolName}|${option}`;
};

const getOptionValueForPercent = (option: string) => {
    return parseFloat(option) / 100;
};

function mapToPicklistOptions(
    optionValues: string[] | undefined,
    symbolName: SymbolSetName | undefined,
    isPercentFormat: boolean | undefined
): PicklistItem[] | undefined {
    if (optionValues == null) {
        return undefined;
    }

    return optionValues.reduce((acc: PicklistItem[], option, index) => {
        let value;
        if (symbolName) {
            value = getOptionValueForSymbol(option, symbolName);
        } else if (isPercentFormat && option.includes('%')) {
            value = getOptionValueForPercent(option);
        }
        acc.push(getOption(value, option, symbolName, index));
        return acc;
    }, []);
}

export function mapToMultiPicklistOptions(obj: string[] | MultiPicklistObjectValue | null | undefined): PicklistItem[] | undefined {
    if (!obj) {
        return undefined;
    }

    let optionValues: string[];
    if (isMultiPicklistObjectValue(obj)) {
        optionValues = obj.values || [];
    } else {
        optionValues = obj as string[];
    }

    return optionValues.reduce((acc: SelectV2Option[], value: string) => {
        if (value) {
            acc.push({ value, label: value });
        }
        return acc;
    }, []);
}

export function mergeMultiSelectOptions(
    multiSelectOptions: SelectV2Option[] = [],
    selectedMultiSelectOptions: SelectV2Option[] = []
): SelectV2Option[] {
    // since all options have the same value and label, we can do this
    // convert SelectV2Option arrays into a string array
    // so we can de-dupe the options using Set
    const options = multiSelectOptions.map((opt) => opt.value);
    const selected = selectedMultiSelectOptions.map((opt) => opt.value);
    const mergedSet = new Set([...options, ...selected]);
    const merged = mapToMultiPicklistOptions([...mergedSet]);
    return merged ? merged : [];
}

function getCheckboxOptions(symbolName: SymbolSetName | undefined): PicklistItem[] {
    const checkboxSetName: SymbolSetName = symbolName || SymbolSetName.NONE;

    return CheckboxOptions.reduce((acc: PicklistItem[], option, index) => {
        const value = `${checkboxSetName}|${option}`;
        acc.push(getOption(value, option, checkboxSetName, index));
        return acc;
    }, []);
}

export const getSelectedItem = (
    options: string[] | undefined,
    symbolName: SymbolSetName | undefined,
    option: string,
    isPercentFormat?: boolean
): PicklistItem => {
    const index: number = options != null && option != null ? options.indexOf(option.toString()) : -1;

    if (index === -1) {
        return {
            value: option,
            label: option,
        };
    }
    let value;
    if (symbolName) {
        value = getOptionValueForSymbol(option, symbolName);
    } else if (isPercentFormat) {
        value = getOptionValueForPercent(option);
    } else {
        value = option;
    }
    return getOption(value, option, symbolName, index);
};

const getOption = (value: any, option: string, symbolName: SymbolSetName | undefined, index: number): PicklistItem => {
    const symbol = SymbolImageUrlResolver(symbolName, index);

    return {
        value: value ?? option,
        label: option,
        symbol,
    };
};
