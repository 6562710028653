import { User as dvUser } from '../../common/interfaces';

export enum EligibilityStatus {
    PLAN_IS_ELIGIBLE = 'PLAN_IS_ELIGIBLE',
    PLAN_IS_NOT_ELIGIBLE = 'PLAN_IS_NOT_ELIGIBLE',
    PLAN_NOT_FOUND = 'PLAN_NOT_FOUND',
    ALREADY_IN_TRIAL = 'ALREADY_IN_TRIAL',
    ALREADY_PURCHASED = 'ALREADY_PURCHASED',
    DISABLED_BY_ADMIN = 'DISABLED_BY_ADMIN',
    IN_COOL_DOWN = 'IN_COOL_DOWN',
}

export interface User extends dvUser {
    eligibility?: EligibilityStatus;
}

export interface GetEligibilityResponse {
    planId: number;
    friendlyPlanId: number;
    productId: string;
    planItemId?: string;
    trialEligible: boolean;
    startDate: string;
    expirationDate: string;
    seamlessTrialEligibility: EligibilityStatus;
    planUSM: boolean;
}
