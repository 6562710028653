import styled from '@emotion/styled';
import { ContainerTree, ContainerTreeData, TreeItemType, useContainerTree } from '@smartsheet/container-tree';
import { Banner, BannerContent, Button, Focusable, SearchInput, Spacer } from '@smartsheet/lodestar-core';
import * as debounce from 'debounce-promise';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ErrorType, ViewSourceType } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { CreateView } from '../../common/interfaces';
import { workspaceSearchFeatureSelector } from '../../containers/App/Selectors';
import { Actions } from '../../containers/Home';
import viewClient from '../../http-clients/View.client';
import { workspaceClient } from '../../http-clients/Workspace.client';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import ModalWrapper from '../Modal';
import Spinner from '../Spinner';
import './CreateViewModal.css';

const SEARCH_LIMIT = 100;
const ControlID = {
    CREATE: 'nvm-1',
    CANCEL: 'nvm-2',
    NEW_VIEW_MODAL: 'nvm-3',
};

interface Props {
    toggleCreateViewModal: () => void;
    displaySheetPicker: () => void;
    isCreateViewModalOpen: boolean;
    sourceApp?: string;
}

export const CreateViewModal = ({ toggleCreateViewModal, displaySheetPicker, isCreateViewModalOpen, sourceApp }: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const languageElements = useLanguageElements();
    const isWorkspaceSearch = useSelector(workspaceSearchFeatureSelector);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
    const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false);

    const selectedItemRef = useRef<ContainerTreeData | null>(null);
    const createButtonRef = useRef<Focusable>(null);

    useEffect(() => {
        setIsLoading(true);
        workspaceClient.getInitialData().then((results) => {
            setIsLoading(false);
            setData(results);
            results.forEach((result) => {
                if (result.childData?.loadState === 'loaded') {
                    expandedSet.add(result);
                }
            });
        });
    }, []);

    const fetchChildren = (parentContainerData: ContainerTreeData): Promise<ContainerTreeData> => {
        if (parentContainerData.containerType === TreeItemType.FOLDER) {
            return parentContainerData.isPersonalWorkspace ? workspaceClient.getFolders() : workspaceClient.getFolder(parentContainerData.id);
        } else {
            return workspaceClient.getWorkspace(parentContainerData.id);
        }
    };

    const { containerTreeProps, data, selectedSet, expandedSet, setData } = useContainerTree({
        data: [],
        fetchChildren,
        parentTypesSelectable: false,
        expandParentTypeOnClick: true,
    });

    const newSelectedItem = selectedSet.values().next().value || null;

    if (selectedItemRef.current !== newSelectedItem) {
        setIsErrorVisible(false);
        selectedItemRef.current = newSelectedItem;
    }

    const isButtonDisabled = selectedSet.size() !== 1 || isErrorVisible;

    const clearSelectedSet = () => {
        if (selectedSet.size() > 0) {
            selectedSet.clear();
        }
    };

    const handleCloseModal = () => {
        toggleCreateViewModal();
        displaySheetPicker();
    };

    const handleCreateViewClick = () => {
        if (selectedSet.size() !== 1) {
            return;
        }
        setIsButtonLoading(true);

        const viewType = selectedItemRef.current?.containerType === TreeItemType.SHEET ? ViewSourceType.SHEET : ViewSourceType.REPORT;
        const newView: CreateView = {
            name: selectedItemRef.current?.name,
            viewSourceType: viewType,
            viewSourceId: Number(selectedItemRef.current?.id),
            sourceApp,
        };

        viewClient
            .create(newView)
            .then((response) => {
                const view = response.data;
                dispatch(Actions.fetchHomeData());
                history.push(`/views/${view.id!}/admin/basic`);
                clearSelectedSet();
            })
            .catch((error) => {
                setIsButtonLoading(false);
                createButtonRef.current?.blur();
                if (error.response?.status === 403 && error.response?.data?.errorCode === ErrorType.USER_NOT_LICENSED) {
                    setIsErrorVisible(true);
                }
            });
    };

    const fetchData = (query?: string) => {
        setIsLoading(true);
        const fetchPromise = query ? workspaceClient.getSearch(query, isWorkspaceSearch) : workspaceClient.getInitialData();

        fetchPromise.then((results) => {
            selectedSet.clear();
            setData(results);
            expandedSet.clear();
            results.forEach((result) => {
                if (result.childData?.loadState === 'loaded') {
                    expandedSet.add(result);
                }
            });
            setIsLoading(false);
        });
    };

    const handleSearchTree = debounce((query: string): void => {
        if (query.length >= 1 && query.length < SEARCH_LIMIT) {
            fetchData(query);
        } else {
            fetchData();
        }
    }, 350);

    return (
        <ModalWrapper isModalOpen={isCreateViewModalOpen} onClose={handleCloseModal} classes={'new-view-modal'}>
            <div className={'CreateViewModal'} data-client-id={ControlID.NEW_VIEW_MODAL}>
                {isErrorVisible && (
                    <StyledErrorBanner type="error" aria-label="create-view-error-banner" data-testid={AutomationIds.VIEW_CREATE_ERROR_BANNER}>
                        <BannerContent>{languageElements.CREATE_VIEW_ERROR_MESSAGE}</BannerContent>
                    </StyledErrorBanner>
                )}
                <span className="create-new-view-text">{languageElements.CREATE_VIEW_TEXT}</span>
                <span className="create-new-view-basic-text">{languageElements.CREATE_NEW_VIEW_BASIC_TEXT}</span>
                <SearchInput aria-label="Search" placeholder={languageElements.VIEW_TABLE_SEARCH} onChange={handleSearchTree} />
                {isLoading ? (
                    <div className="spinner create-modal-picker">
                        <Spinner label={languageElements.SPINNER_LOADING_LABEL} />
                    </div>
                ) : (
                    <div className="create-modal-picker" data-testid="ContainerTree">
                        <ContainerTree {...containerTreeProps} data={data} />
                    </div>
                )}
                <StyledSpacer space={'small'}>
                    <Button tabIndex={0} data-client-id={ControlID.CANCEL} appearance={'borderless'} onClick={handleCloseModal}>
                        {languageElements.MODAL_CANCEL_BUTTON_DEFAULT_TEXT}
                    </Button>
                    <Button
                        tabIndex={0}
                        clientID={ControlID.CREATE}
                        appearance={'primary'}
                        onClick={handleCreateViewClick}
                        isLoading={isButtonLoading}
                        isDisabled={isButtonDisabled}
                        ref={createButtonRef}
                    >
                        {languageElements.MODAL_CREATE_BUTTON_DEFAULT_TEXT}
                    </Button>
                </StyledSpacer>
            </div>
        </ModalWrapper>
    );
};

const StyledSpacer = styled(Spacer)`
    margin-top: 1rem;
    justify-content: end;
`;

const StyledErrorBanner = styled(Banner)`
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 100%;
`;
