import { fromJS, Map } from 'immutable';
import { AppStage } from '../../common/enums/AppStage.enum';
import { Feature } from '../../common/enums/Feature';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { NotificationType } from '../../common/enums/NotificationType.enum';
import { isInIframe } from '../../common/utils/IsInIframe';
import LeftPanelType from '../LeftPanel/LeftPanelType';
import { Actions, ActionTypes } from './Actions';

export interface HomeSourceFilter {
    id: number;
    name: string;
    isReport: boolean;
}

const initialState = fromJS({
    leftSidePanelOpened: false,
    activeSidePanel: LeftPanelType.HOME,
    homeFilter: HomeFilter.ALL,
    homeSourceFilter: undefined,
    error: undefined,
    stage: undefined,
    redirectUrl: undefined,
    actionInProgressMessage: undefined,
    inIframe: isInIframe(),
    notifications: undefined,
    featureFlags: Map<Feature, FeatureDetails>(),
});

export interface FeatureDetails {
    isEnabled: boolean;
    value?: string;
}

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case ActionTypes.TOGGLE_LEFT_SIDE_PANEL:
            const leftSidePanelOpened: boolean = state.get('leftSidePanelOpened');
            return state.set('leftSidePanelOpened', !leftSidePanelOpened);
        case ActionTypes.SET_ACTIVE_SIDE_PANEL:
            return state.set('activeSidePanel', action.payload);
        case ActionTypes.SET_APP_STAGE_ERROR:
            return state.merge({
                error: action.payload,
                stage: AppStage.Error,
            });
        case ActionTypes.SET_APP_STAGE_ACTION_IN_PROGRESS:
            return state.merge({
                actionInProgressMessage: action.payload,
                stage: AppStage.ActionInProgress,
            });
        case ActionTypes.SET_APP_STAGE_REDIRECT:
            return state.merge({
                redirectUrl: action.payload,
                stage: AppStage.Redirect,
            });
        case ActionTypes.RESET_APP_STAGE:
            return state.merge({
                redirectUrl: undefined,
                stage: undefined,
                error: undefined,
                message: undefined,
            });
        case ActionTypes.ADD_NOTIFICATION: {
            const notifications = state.get('notifications') ? [...state.get('notifications')] : [];
            notifications.push({ type: NotificationType.PENDING_RECEIVED_TRANSFERS });
            return state.set('notifications', notifications);
        }
        case ActionTypes.REMOVE_NOTIFICATION: {
            const notifications = state.get('notifications') ? [...state.get('notifications')] : [];
            notifications.shift();
            return state.set('notifications', notifications);
        }
        case ActionTypes.OPEN_LEFT_SIDE_PANEL:
            return state.get('leftSidePanelOpened') ? state : state.set('leftSidePanelOpened', true);
        case ActionTypes.STORE_FEATURE_FLAGS:
            const featureFlags: Map<Feature, FeatureDetails> = state.get('featureFlags');
            const updatedFeatureFlags = featureFlags.withMutations((mutable) =>
                action.payload.forEach((featureFlag) =>
                    mutable.set(featureFlag.feature, { isEnabled: featureFlag.isEnabled, value: featureFlag.value })
                )
            );
            return state.set('featureFlags', updatedFeatureFlags);

        // Home
        case ActionTypes.SET_HOME_FILTER:
            return state.set('homeFilter', action.payload);
        case ActionTypes.SET_HOME_SOURCE_FILTER:
            return state.set('homeSourceFilter', action.payload);
    }
    return state;
};

export default reducer;
