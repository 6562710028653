import * as React from 'react';
import { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ArrowKeyStepper,
    AutoSizer,
    CellMeasurerCache,
    Column,
    Index,
    IndexRange,
    InfiniteLoader,
    OverscanIndexRange,
    ScrollIndices,
    Table,
    TableCellProps,
} from 'react-virtualized';
import { AsyncStatus } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { ActionType, UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import * as AppActions from '../../containers/App/Actions';
import { iframeStatusSelector } from '../../containers/App/Selectors';
import * as ReportActions from '../../containers/View/Report/Actions';
import { reportColumnsSelector, reportDataSelector, reportJobSelector } from '../../containers/View/Report/Selectors';
import { loggingClient } from '../../http-clients/Logging.client';
import CellText from './Cells/CellText';
import { RowStatus } from './Cells/RowStatus';
import GridHeader from './GridHeader';
import { getAutoSizerHeight, getRowData, GRID_MIN_ROW_HEIGHT, GRID_OVERSCAN_ROW_COUNT, updateFocusToGrid } from './GridUtils';
import GridHeaderRow from './Rows/GridHeaderRow';
import GridRowElement from './Rows/GridRowElement';
import './Grid.css';

const FILENAME = 'Grid.tsx';
const COLUMN_STATUS_WIDTH = 36;

// Threshold at which to pre-fetch data. A threshold of 200 means that data will start loading when a user scrolls within 200 rows of the end.
const INFINITE_LOADER_THRESHOLD = 200;
const SKELETON_ROW_COUNT = 50;

interface GridProps {
    viewId: string;
    wrapText: boolean;
    showFormats: boolean;
    disableSetFocus: boolean;
}

type nextPageLoadDetailsType = { inProgress: false; page: number } | { inProgress: true; page: number; totalRows: number };

const Grid = ({ viewId, wrapText, showFormats, disableSetFocus }: GridProps) => {
    const dispatch = useDispatch();
    const [columnHeaderHeight, setColumnHeaderHeight] = useState(GRID_MIN_ROW_HEIGHT);
    const [scrollToRow, setScrollToRow] = useState(-1);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const rowHeightCacheRef = useRef<CellMeasurerCache | undefined>(
        wrapText
            ? new CellMeasurerCache({
                  fixedWidth: true,
                  minHeight: GRID_MIN_ROW_HEIGHT,
              })
            : undefined
    );

    const currentIndexRef = useRef({ start: 0, stop: 0 });
    const overscanIndexRef = useRef({ start: 0, stop: 0 });
    const firstPageLoadDetailsRef = useRef({ loaded: false, startTime: Date.now() });
    const nextPageLoadDetailsRef = useRef<nextPageLoadDetailsType>({ inProgress: false, page: 1 });
    const tableRef = useRef<Table>(null);
    const reportJob = useSelector(reportJobSelector);
    const reportData = useSelector(reportDataSelector);
    const columnData = useSelector(reportColumnsSelector);
    const inIframe = useSelector(iframeStatusSelector);

    useEffect(() => {
        dispatch(ReportActions.Actions.loadReportJob({ viewId, forceReload: true }));
        dispatch(ReportActions.Actions.fetchReportColumns(viewId));
    }, [dispatch, viewId]);

    useEffect(() => {
        if (reportJob.status === AsyncStatus.ERROR) {
            dispatch(AppActions.Actions.setAppStageError(reportJob.error));
            return;
        }

        if (reportData.status === AsyncStatus.ERROR) {
            dispatch(AppActions.Actions.setAppStageError(reportData.error));
            return;
        }

        if (reportData.status !== AsyncStatus.DONE || columnData.status !== AsyncStatus.DONE) {
            return;
        }

        if (!firstPageLoadDetailsRef.current.loaded) {
            firstPageLoadDetailsRef.current.loaded = true;
            const duration = Date.now() - firstPageLoadDetailsRef.current.startTime;

            loggingClient.logInfo({
                file: FILENAME,
                func: 'useEffect',
                message: 'Grid loaded',
                duration,
                viewId,
            });

            UserAnalyticsAction.add(ActionType.DURATION, 'loadView', {
                duration,
                hasError: false,
                legacy: false,
            });
        }

        // React-virtualized requires the header height to be passed in as number to display the grid correctly
        // but we don't know the correct height initially because we wrap long column headers.
        // So here we get the height attribute for 'header-row' (which is set to auto height)
        // and update state.
        const headerRow = document.getElementsByClassName('header-row')[0] as HTMLElement;
        if (!headerRow) {
            return;
        }
        setColumnHeaderHeight(headerRow.offsetHeight);
    }, [reportData, columnData, viewId, reportJob.status, reportJob, dispatch]);

    /**
     * If wrapText is toggled on/off, we need to force update the component to ensure the correct row heights
     */
    useEffect(() => {
        rowHeightCacheRef.current = wrapText
            ? new CellMeasurerCache({
                  fixedWidth: true,
                  minHeight: GRID_MIN_ROW_HEIGHT,
              })
            : undefined;
        forceUpdate();
    }, [wrapText]);

    /**
     * If wrapText is enabled, we need to recompute row heights when the additional fetched pages have been loaded. Only the first X rows of the
     * additional loaded page needs to have their heights recomputed because they were originally showing the skelton loaders while the API request
     * was in progress
     */
    useEffect(() => {
        if (reportData.status !== AsyncStatus.DONE || !nextPageLoadDetailsRef.current.inProgress) {
            return;
        }

        const { totalRows: currentTotalRows, page } = nextPageLoadDetailsRef.current;
        nextPageLoadDetailsRef.current = { inProgress: false, page };

        const { start: overscanStartIndex, stop: overscanStopIndex } = overscanIndexRef.current;

        if (overscanStartIndex >= currentTotalRows && overscanStartIndex <= currentTotalRows + SKELETON_ROW_COUNT) {
            if (wrapText) {
                // Clear the cache for the rows that were rendered as skeleton loaders and recompute their heights
                rowHeightCacheRef.current?.clearAll();

                for (let i = overscanStartIndex; i <= overscanStopIndex; i++) {
                    tableRef.current?.recomputeRowHeights(i);
                }
            }
            tableRef.current?.forceUpdate();
        }
    }, [reportData.status, wrapText]);

    if ((reportData.status !== AsyncStatus.DONE && reportData.status !== AsyncStatus.PARTIAL) || columnData.status !== AsyncStatus.DONE) {
        return null;
    }

    const { rows, pagination } = reportData.data;

    const handleLoadMoreRows = (): Promise<void> => {
        // If there is no nextCursor or the next page of report row data is in progress, we don't need to fetch more rows
        if (!pagination.nextCursor || nextPageLoadDetailsRef.current.inProgress) {
            return Promise.resolve();
        }

        // Next page of data is being fetched which means the first X rows of the additional page will be shown as skeleton loaders
        nextPageLoadDetailsRef.current = { inProgress: true, totalRows: rows.length, page: nextPageLoadDetailsRef.current.page + 1 };

        dispatch(
            ReportActions.Actions.fetchReportData({
                viewId,
                page: nextPageLoadDetailsRef.current.page,
                pagination: { limit: pagination.limit, nextCursor: pagination.nextCursor },
            })
        );

        return Promise.resolve();
    };

    const handleIsRowLoaded = ({ index }: Index) => {
        return !!rows[index];
    };

    /**
     * Get the CSS class to apply to each row (including the header row).
     *
     * @param getter
     */
    const handleSettingRowClass = ({ index }: Index): string => {
        if (index < 0) {
            // Header row
            return 'header-row';
        }
        if (scrollToRow === index) {
            // Selected content row
            return 'data-row highlight-row';
        }

        // Content row
        return 'data-row';
    };

    const handleScrollToChange = ({ scrollToRow: scrollToRowInput }: ScrollIndices) => {
        // Figure out if user has arrowed beyond the visible grid. If yes, adjust the startIndex
        const currentIndexes = currentIndexRef.current;
        if (scrollToRowInput >= currentIndexes.stop) {
            currentIndexes.start = currentIndexes.start + 1;
        } else if (scrollToRowInput < currentIndexes.start) {
            currentIndexes.start = currentIndexes.start - 1;
        }

        updateFocusToGrid(inIframe, disableSetFocus);
        setScrollToRow(scrollToRowInput);
    };

    const handleGetRowData = ({ index }: Index) => {
        return getRowData(rows, index);
    };

    const handleRenderCellStatus = (props: TableCellProps) => (
        <RowStatus tableCellProps={props} wrapText={wrapText} rowHeightCache={rowHeightCacheRef.current} />
    );
    const handleRenderCellContent = (props: TableCellProps) => (
        <CellText tableCellProps={props} wrapText={wrapText} rowHeightCache={rowHeightCacheRef.current} />
    );
    const handleRenderNoRows = () => <div>No rows to display</div>;

    const totalActualRows = rows.length;

    // To allow for infinite scrolling, we need to add the SKELETON_ROW_COUNT to the total rows if there is a nextCursor because the API do not
    // return the total number of rows
    const totalRowsPlusSkeletonRows = reportData.data.pagination.nextCursor ? totalActualRows + SKELETON_ROW_COUNT : totalActualRows;

    const columns = columnData.data;
    const gridClassName = `inner-grid ${wrapText && rowHeightCacheRef.current ? 'wrap-text ' : ''}` + `${showFormats ? 'show-formats' : ''}`;
    const autoSizerStyle = getAutoSizerHeight(totalRowsPlusSkeletonRows, columns, inIframe);

    return (
        <div data-client-id={AutomationIds.VIEW_TABLE}>
            <div id="control-height" />
            <ArrowKeyStepper
                onScrollToChange={handleScrollToChange}
                columnCount={1}
                rowCount={totalActualRows}
                mode={'cells'}
                scrollToColumn={1}
                scrollToRow={scrollToRow}
            >
                {({ onSectionRendered, scrollToColumn }) => (
                    <AutoSizer className="autosizer" disableWidth={false} style={autoSizerStyle}>
                        {({ height, width }) => (
                            <InfiniteLoader
                                isRowLoaded={handleIsRowLoaded}
                                loadMoreRows={handleLoadMoreRows}
                                rowCount={totalRowsPlusSkeletonRows}
                                threshold={INFINITE_LOADER_THRESHOLD}
                            >
                                {({ onRowsRendered, registerChild }) => {
                                    registerChild(tableRef.current);

                                    const handleRowsRendered = (info: IndexRange & OverscanIndexRange) => {
                                        overscanIndexRef.current = { start: info.overscanStartIndex, stop: info.overscanStopIndex };
                                        currentIndexRef.current = { start: info.startIndex, stop: info.stopIndex };
                                        onRowsRendered(info);
                                    };

                                    return (
                                        <Table
                                            ref={tableRef}
                                            deferredMeasurementCache={rowHeightCacheRef.current}
                                            disableHeader={false}
                                            className="grid-table"
                                            gridClassName={gridClassName}
                                            headerClassName={'header-column'}
                                            headerHeight={columnHeaderHeight}
                                            headerRowRenderer={GridHeaderRow}
                                            height={height}
                                            noRowsRenderer={handleRenderNoRows}
                                            columnCount={1}
                                            onSectionRendered={onSectionRendered}
                                            overscanRowCount={GRID_OVERSCAN_ROW_COUNT}
                                            rowHeight={rowHeightCacheRef.current ? rowHeightCacheRef.current.rowHeight : GRID_MIN_ROW_HEIGHT}
                                            rowGetter={handleGetRowData}
                                            rowClassName={handleSettingRowClass}
                                            rowCount={totalRowsPlusSkeletonRows}
                                            rowRenderer={GridRowElement}
                                            scrollToColumn={scrollToColumn}
                                            width={width}
                                            onRowsRendered={handleRowsRendered}
                                            scrollToAlignment={'start'}
                                        >
                                            <Column
                                                key={'status-column'}
                                                width={COLUMN_STATUS_WIDTH}
                                                minWidth={COLUMN_STATUS_WIDTH}
                                                label={''}
                                                dataKey={'status-column'}
                                                cellRenderer={handleRenderCellStatus}
                                                className="outer-cell"
                                            />
                                            {columns?.map((column) => (
                                                <Column
                                                    key={column.id}
                                                    width={column.width}
                                                    minWidth={column.width}
                                                    label={column.title}
                                                    dataKey={column.id.toString()}
                                                    cellRenderer={handleRenderCellContent}
                                                    headerRenderer={GridHeader}
                                                    className="outer-cell"
                                                />
                                            ))}
                                        </Table>
                                    );
                                }}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                )}
            </ArrowKeyStepper>
        </div>
    );
};

export default Grid;
