import { AdminControlsData } from '../../common/interfaces';
import { compareStrings, withNullChecks } from '../../common/utils';
import {getAccessReport} from '../../http-clients/AccessReportClient';

type CheckedState = 'checked' | 'unchecked' | 'indeterminate';

export const getCheckedState = (selectedItems: number, totalViews: number): CheckedState => {
    if (selectedItems === 0) {
        return 'unchecked';
    }

    if (selectedItems === totalViews) {
        return 'checked';
    }

    return 'indeterminate';
};

export const searchAndFilterViews = (views: Map<string, AdminControlsData>, searchString: string): Map<string, AdminControlsData> => {
    const sanitizeSearchString = searchString.replace(/[-[\]{}()+?.,*\\^$|]/g, '\\$&').trim();

    if (!sanitizeSearchString) {
        return views;
    }

    const newViews = new Map<string, AdminControlsData>();

    views.forEach((view) => {
        try {
            const isOwnerNameMuchPattern = view.currentOwner.name && new RegExp(sanitizeSearchString, 'gi').test(view.currentOwner.name);
            const isOwnerEmailMuchPattern = view.currentOwner.email && new RegExp(sanitizeSearchString, 'gi').test(view.currentOwner.email);
            const isViewNameMatchPattern = view.name && new RegExp(sanitizeSearchString, 'gi').test(view.name);
            const isViewDescriptionMatchPattern = view.description && new RegExp(sanitizeSearchString, 'gi').test(view.description);

            if (isOwnerNameMuchPattern || isViewNameMatchPattern || isViewDescriptionMatchPattern || isOwnerEmailMuchPattern) {
                newViews.set(view.id, view);
            }
        } catch (_) {}
    });

    return newViews;
};

export const compareFunctions: any = {
    name: (isReversed?: boolean) => withNullChecks(compareStrings, (o: [string, AdminControlsData]) => o[1].name, isReversed),
    description: (isReversed?: boolean) => withNullChecks(compareStrings, (o: [string, AdminControlsData]) => o[1].description, isReversed),
    owner: (isReversed?: boolean) =>
        withNullChecks(
            compareStrings,
            (o: [string, AdminControlsData]) => {
                if (!o[1].currentOwner) {
                    return '';
                }

                return o[1].currentOwner.name;
            },
            isReversed
        ),
};

/**
 * Download a CSV file from a backend response
 *
 * @param api
 * @param filename
 * @returns {Promise<void>}
 */
export async function downloadCSVFromResponse(filename: string | null) {
    try {
        // Make a GET request to the backend endpoint
        const response = await getAccessReport();
        const url = response.s3Uri;

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;

        // Give the name to the file
        if (filename) {
            link.download = filename;
        } else {
            link.download = 'download.csv'; // Default filename if not found in the header
        }

        // Simulate a click on the link to trigger the download
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the link and revoke the Blob URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        throw new Error('Error downloading CSV');
    }
}
