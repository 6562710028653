export enum ActEligibilityStatus {
    PLAN_IS_ELIGIBLE = 'PLAN_IS_ELIGIBLE',
    PLAN_IS_NOT_ELIGIBLE = 'PLAN_IS_NOT_ELIGIBLE',
    PLAN_NOT_FOUND = 'PLAN_NOT_FOUND',
    ALREADY_IN_TRIAL = 'ALREADY_IN_TRIAL',
    ALREADY_PURCHASED = 'ALREADY_PURCHASED',
    DISABLED_BY_ADMIN = 'DISABLED_BY_ADMIN',
    IN_COOL_DOWN = 'IN_COOL_DOWN',
}

export interface PlanInfo {
    planId: number;
    planUSM: boolean;
    planEligibilityStatus: ActEligibilityStatus;
    planIsEligible: boolean;
    planOrgInfo: {
        eligibilityStatus: ActEligibilityStatus;
        isEligible: boolean;
    };
    viewId?: string;
}
