import { Epic, ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import { Actions, ActionTypes } from './Actions';

export const fetchFeatureFlagsEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_FEATURE_FLAGS),
        switchMap(() => from(featureFlagsClient.get()).pipe(map(Actions.storeFeatureFlags)))
    );
