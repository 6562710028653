import * as React from 'react';
import { SortIndicator, TableHeaderProps } from 'react-virtualized';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';

const GridHeader = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    const showSortIndicator = sortBy === dataKey;
    const showFilterIconForColumn = false;
    return (
        <div className="header-cell" data-client-type={AutomationTypes.GRID_HEADER_CELL} data-filter-applied={showFilterIconForColumn}>
            <p
                className="ReactVirtualized__Table__headerTruncatedText"
                key="label"
                title={label as string}
                data-client-id={AutomationIds.VIEW_COLUMN_HEADER}
            >
                {label}
            </p>
            {showSortIndicator && (
                <div className="sort-icon" data-client-id={AutomationIds.VIEW_COLUMN_SORT_ICON}>
                    <SortIndicator key="SortIndicator" sortDirection={sortDirection} />
                </div>
            )}
        </div>
    );
};

export default GridHeader;
