import { AsyncStatus, ColumnType, HttpStatusCodes } from '../../../common/enums';
import { DetailsPanelTabType } from '../../../common/enums/DetailsPanelTabType.enum';
import { Attachment, Discussion, FormFieldInterface, Image, IPaginatedResult, SmartsheetUser } from '../../../common/interfaces';
import { isNotUndefined } from '../../../common/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { isAxiosErrorWithResponse } from '../../../common/utils/isAxiosErrorWithResponse';
import withSetAppError, { WithSetAppErrorProps } from '../../../components/hoc/WithSetAppError';
import { loggingClient } from '../../../http-clients/Logging.client';
import viewClient from '../../../http-clients/View.client';
import { StoreState } from '../../../store';
import { Actions as ImageActions } from '../../../store/images/Actions';
import * as ViewActions from '../Actions';
import { currentRowSheetIdSelector } from '../Selectors';
import { DetailsLegacy } from './DetailsLegacy';
import { mapFormFieldsToSubmittedForm } from './mapFormFieldsToSubmittedForm';
import { RowUpsert } from './RowUpsert';
import { latestCurrentRowUpsertSelector, selectedRowIdSelector, selectStatusForCurrentRow } from './Selectors';

interface OwnProps {
    viewId: string;
    width: number;
    displayComments: boolean;
    addComments: boolean;
    displayAttachments: boolean;
    addAttachments: boolean;
    onChangeIsDirty: (isDirty: boolean) => void;
    smartsheetUsers: IPaginatedResult<SmartsheetUser>;
    onCloseDetailsPanel: () => void;
    detailsPanelDescription?: string;
    detailsPanelInitialTab?: DetailsPanelTabType;
}

interface StateProps {
    selectedRowId?: string;
    rowUpsert?: RowUpsert;
    rowUpsertStatus?: AsyncStatus;
    sheetId?: number;
}

interface DispatchProps {
    fetchImageUrls: (images: Image[]) => ImageActions;
    removeGridRow: (viewId: string, rowId: string) => ViewActions.Actions;
}

export type DetailsContainerProps = OwnProps & WithSetAppErrorProps & StateProps & DispatchProps;

interface State {
    form?: FormFieldInterface[];
    attachments?: Attachment[];
    discussions?: Discussion[];
    isLoading: boolean;
}

const FILENAME = 'DetailsContainer.tsx';

export class DetailsContainer extends React.Component<DetailsContainerProps, State> {
    public constructor(props: DetailsContainerProps) {
        super(props);

        this.state = this.getInitializeState();
    }

    private resolveFormContent() {
        const rowUpsert = this.props.rowUpsert;
        const detailsDataSaveStatus = this.props.rowUpsertStatus;
        let form = this.state.form;
        const attachments = this.state.attachments;
        const discussions = this.state.discussions;

        switch (detailsDataSaveStatus) {
            case AsyncStatus.NOT_STARTED:
                return { form, attachments, discussions };
            case AsyncStatus.IN_PROGRESS:
                // if the save was successful and the form is returned as undefined, we will render <RowRemovedFromView> instead of the form.
                // we assign `form = undefined` so we know this inside <Details>
                if (rowUpsert?.updatedForm === undefined) {
                    form = undefined;
                } else {
                    form = this.mapContactColumns(rowUpsert.updatedForm);
                }
                return { form, attachments, discussions };
            case AsyncStatus.DONE:
            case AsyncStatus.ERROR:
            default:
                if (this.state.form) {
                    form = this.mapContactColumns(this.state.form);
                }
                return { form, attachments, discussions };
        }
    }

    public render(): JSX.Element {
        const rowUpsert = this.props.rowUpsert;
        const detailsDataSaveStatus = this.props.rowUpsertStatus;

        const detailsDataSubmittedForm = mapFormFieldsToSubmittedForm(rowUpsert?.submittedForm);
        let isNewSubmission = this.props.selectedRowId == null; // before saving a new row, selectedRowId will be null
        isNewSubmission = rowUpsert?.isNewSubmission ?? isNewSubmission; // after saving, use rowUpsert.isNewSubmission

        const { form, attachments, discussions } = this.resolveFormContent();

        return (
            <DetailsLegacy
                viewId={this.props.viewId}
                rowId={this.props.selectedRowId}
                width={this.props.width}
                displayComments={this.props.displayComments}
                addComments={this.props.addComments}
                displayAttachments={this.props.displayAttachments}
                addAttachments={this.props.addAttachments}
                onChangeIsDirty={this.props.onChangeIsDirty}
                smartsheetUsers={this.props.smartsheetUsers}
                isNewSubmission={isNewSubmission}
                isLoading={this.state.isLoading}
                form={form}
                attachments={attachments}
                discussions={discussions}
                detailsDataSaveStatus={detailsDataSaveStatus}
                detailsDataSubmittedForm={detailsDataSubmittedForm}
                detailsPanelDescription={this.props.detailsPanelDescription}
                getRowData={this.fetchRowData.bind(this)}
                initialTab={this.props.detailsPanelInitialTab}
            />
        );
    }

    private fetchRowData(): void {
        this.getRowData(this.props.selectedRowId);
    }

    public componentDidMount(): void {
        const currentRowUpsertRequestStatus = this.props.rowUpsertStatus;

        if (currentRowUpsertRequestStatus !== AsyncStatus.ERROR) {
            this.initializeState();
            // getRowData method is an async method called without the await causing this to fire and forget
            this.getRowData(this.props.selectedRowId);
        }
    }

    public componentDidUpdate(prevProps: DetailsContainerProps): void {
        const rowUpsert = this.props.rowUpsert;
        const isNewSubmission = rowUpsert != null && rowUpsert.isNewSubmission;
        const selectedRowChanged = this.props.selectedRowId !== prevProps.selectedRowId;

        const saveStatus = this.props.rowUpsertStatus;
        if (saveStatus === AsyncStatus.IN_PROGRESS && this.state.form != null) {
            // if the save was successful and the form is returned as undefined, we will render <RowRemovedFromView> instead of the form.
            // we set state `form: undefined` so it stays this way after upsert is cleared
            if (rowUpsert?.updatedForm === undefined) {
                this.setState({ form: undefined });
            } else if (this.state.form !== rowUpsert?.updatedForm) {
                this.setState({ form: rowUpsert.updatedForm });
            }
        }
        // This condition is met when a user clicks on a grid row to open the details panel. It is not met when a request to insert a new row
        // completes successfully. In the latter, the selectedRowChanged would be true because the temporary row ID has been replaced with the
        // row ID from the completed insert request
        if (selectedRowChanged && !isNewSubmission) {
            this.initializeComponentForNewSelectedRow();
            return;
        }
    }

    //#endregion

    private async getRowData(selectedRowId: string | undefined) {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);

        this.setState({
            isLoading: true,
        });

        try {
            let rowData;
            if (selectedRowId) {
                const response = await viewClient.getViewRow(this.props.viewId, selectedRowId, this.props.sheetId);

                rowData = response.data;
            } else {
                const response = await viewClient.getViewRowForm(this.props.viewId);
                rowData = response.data;
            }

            const formFieldsWithCellImages = rowData.form.filter((formField) => formField.image);
            if (formFieldsWithCellImages.length) {
                const images = formFieldsWithCellImages.map((formField) => formField.image).filter(isNotUndefined);
                this.props.fetchImageUrls(images);
            }

            // This check stops the issue when the async request is in progress, but the user has clicked on a different row. In that case, we only
            // update the state if the selectedRowId matches the props.selectedRowId.
            if (selectedRowId === this.props.selectedRowId) {
                const form = this.mapContactColumns(rowData.form);
                const attachments = rowData.attachments;
                const discussions = rowData.discussions;

                this.setState({
                    form,
                    attachments,
                    discussions,
                    isLoading: false,
                });
            }
        } catch (error) {
            if (isAxiosErrorWithResponse(error) && error.response!.status === HttpStatusCodes.NOT_FOUND) {
                // If a 404 (Not Found) error is returned, then the row no longer exists in the view and
                // the details panel is closed
                loggingClient.logInfo({
                    file: FILENAME,
                    message: `Getting row data failed, row not in the view`,
                    viewId: this.props.viewId,
                    rowId: this.props.selectedRowId,
                });
                this.props.removeGridRow(this.props.viewId, this.props.selectedRowId!);
                this.props.onCloseDetailsPanel();
                this.props.onSetAppStageError(error);
            } else {
                loggingClient.logError(FILENAME, 'getRowData', error, {
                    viewId: this.props.viewId,
                    rowId: this.props.selectedRowId,
                });

                this.setState(
                    {
                        isLoading: false,
                    },
                    () => this.props.onSetAppStageError(error)
                );
            }
        }
    }

    private initializeComponentForNewSelectedRow(): void {
        const currentRowUpsertRequestStatus = this.props.rowUpsertStatus;

        if (currentRowUpsertRequestStatus === AsyncStatus.ERROR) {
            // No need to fetch row data when row contains upsert error from a previous failed upsert request. In this case, the submitted row data
            // will populate the form allowing the user to re-submit the form

            // Set form to original row data from redux store. This allows the user to discard the submitted changes and return to a form state prior
            // to the submitting of the failed form data
            this.setState({
                isLoading: false,
            });
        } else {
            this.initializeState();
            // getRowData method is an async method called without the await causing this to fire and forget
            this.getRowData(this.props.selectedRowId);
        }
    }

    private getInitializeState(): State {
        const currentRowUpsertRequestStatus = this.props.rowUpsertStatus;
        if (currentRowUpsertRequestStatus === AsyncStatus.ERROR) {
            return {
                isLoading: false,
            };
        } else {
            return {
                isLoading: true,
            };
        }
    }

    /**
     * If the column is of type CONTACT_LIST or MULTI_CONTACT_LIST and column validation is turned off, replace the list of
     * contact options that was returned with the column with a list of Smartsheet Users from the users org
     */
    private mapContactColumns(form: FormFieldInterface[]): FormFieldInterface[] {
        return form.map((formField: FormFieldInterface) => {
            const updatedFormField = { ...formField };
            if (formField.type !== ColumnType.CONTACT_LIST && formField.type !== ColumnType.MULTI_CONTACT_LIST) {
                return updatedFormField;
            }

            if (formField.validation !== false) {
                return updatedFormField;
            }

            updatedFormField.contactOptions = this.props.smartsheetUsers.data.map((user: SmartsheetUser) => ({
                id: String(user.id),
                name: user.name,
                email: user.email,
            }));
            return updatedFormField;
        });
    }

    private initializeState() {
        this.setState({
            form: undefined,
            attachments: undefined,
            discussions: undefined,
            isLoading: true,
        });
    }
}

const mapState = (state: StoreState): StateProps => ({
    selectedRowId: selectedRowIdSelector(state),
    rowUpsert: latestCurrentRowUpsertSelector(state),
    rowUpsertStatus: selectStatusForCurrentRow(state),
    sheetId: currentRowSheetIdSelector(state),
});

const mapDispatch = {
    removeGridRow: ViewActions.Actions.removeGridRow,
    fetchImageUrls: ImageActions.fetchImageUrls,
};

export default withSetAppError(connect<StateProps, DispatchProps>(mapState, mapDispatch)(DetailsContainer));
