import { ComponentType } from 'react';
import { Omit } from '../common/utils';
import { instance, LanguageElements } from './LanguageElements';
import * as React from 'react';

export interface LanguageElementsProp {
    languageElements: LanguageElements;
}

export const getLanguageElements = () => {
    return instance;
};

export const useLanguageElements = () => {
    return getLanguageElements();
};

export function withLanguageElementsHOC<BaseProps extends LanguageElementsProp>(TheComponent: ComponentType<BaseProps>) {
    return React.forwardRef(function withLanguageElementsBase(props: Omit<BaseProps, 'languageElements'>, ref) {
        return <TheComponent {...(props as BaseProps)} forwardedRef={ref} languageElements={instance} />;
    });
}
