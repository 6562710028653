import * as React from 'react';
import { ErrorInfo, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HttpStatusCodes, Locale } from '../../common/enums';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { generateRandomNumberInGivenRange, getUrlBarHeight } from '../../common/utils';
import HamburgerMenu from '../../components/HamburgerMenu/HamburgerMenu';
import Header from '../../components/Header';
import Main from '../../components/Main';
import MainFallback from '../../components/Main/MainFallback';
import { TOOLTIP_ROOT_ID } from '../../components/TooltipLegacy';
import featureFlagsClient from '../../http-clients/FeatureFlagsClient';
import licensingClient from '../../http-clients/LicensingClient';
import { loggingClient } from '../../http-clients/Logging.client';
import usersClient from '../../http-clients/Users.client';
import { changeLanguage } from '../../language-elements/LanguageElements';
import { Actions as ActionsAuth } from '../Auth/Actions';
import { isAuthenticatedSelector } from '../Auth/Selectors';
import LeftPanelContainer from '../LeftPanel/LeftPanelContainer';
import { Actions as ActionsApp } from './Actions';
import './App.css';
import { createUrlPath, handleAppErrors } from './AppUtils';
import './Media.css';
import { iframeStatusSelector } from './Selectors';

const App: React.FC = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(isAuthenticatedSelector);
    const inIframe = useSelector(iframeStatusSelector);
    let messageReceived = false;
    const loadFeatureFlags = async () => {
        const featureFlagsResponse = await featureFlagsClient.get();
        dispatch(ActionsApp.storeFeatureFlags(featureFlagsResponse));
    };

    const loadUserData = async (): Promise<void> => {
        // Since the DV session cookie is HttpOnly, we need to make a request to the DV api to see if the user already has a valid DV session.
        try {
            // Issue a GET request to an auth endpoint to check the user's session information.
            // If the endpoint returns a 401 Unauthorized, the axios library will convert it and throw an error.
            const user = await usersClient.getCurrentUser();

            // OwnershipTransferContainer expects email to be in lower case.
            // Might as well change it as soon as we receive it back from Smartsheet, so that it is consistently lower case in DV.
            if (user.email) {
                user.email = user.email.toLowerCase();
            }

            // Sanitize user's locale.
            user.locale = user.locale ? user.locale.replace('_', '-') : Locale.EN_US;

            const shortLocale = user.locale ? user.locale.slice(0, 2) : undefined;

            if (shortLocale) {
                await changeLanguage(shortLocale);
                loggingClient.logInfo({
                    file: 'App.tsx',
                    message: 'Set user locale',
                    newLocale: shortLocale,
                });
            }

            // Get the user's eligibility status. This includes a status of ALREADY_PURCHASED if the user has a license.
            user.eligibility = await licensingClient.getEligibility();

            // Set the user details used throughout the user's session for user analytics.
            // If a user had previously been logged in, their user details would be overridden
            UserAnalyticsGlobalContext.setUser(user);

            // Load the feature flags for the user
            await loadFeatureFlags();

            // If no error is thrown, then the user's session cookies were valid, and we can consider them logged into the application.
            dispatch(ActionsAuth.logIn(user));
        } catch (error) {
            if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
                // Redirect to SMAR log in screen as the user is unauthenticated
                const encodedPath: string = encodeURIComponent(location.pathname);

                // Encode query params with the `?` excluded
                const encodedQueryParams = encodeURIComponent(location.search.substring(1));
                const redirectUrl = `/b/home?dlp=${encodedPath}&dlq=${encodedQueryParams}`;

                // Google security does not allow google sign in page to be open in an iframe,
                // The flow for auth DV embedded in an Iframe:
                // Open new window with google sign in page
                // Set up a broadcast channel to post a message once a user logs in
                // Listen for message with data 'refresh', once received, refresh original page and close a new page
                if (inIframe) {
                    const encodedLoginParameters = encodeURIComponent('rdl=true');
                    const redirectUrlWithEncodedLoginParameters = `/b/home?dlp=${encodedPath}&dlq=${encodedLoginParameters}`;
                    // Wait for a random duration between 500s and 1500 ms
                    // before opening the window to handle scenario of multiple DV embedded
                    setTimeout(() => {
                        const channel = new BroadcastChannel('refresh-channel');
                        if (!messageReceived) {
                            channel.postMessage('window-open');
                            window.open(redirectUrlWithEncodedLoginParameters);
                        }
                    }, generateRandomNumberInGivenRange(500, 1001));
                } else {
                    window.location.replace(redirectUrl);
                }
            }
        }
    };

    // Handles a case when DV (multiple or single) is embedded in an Iframe and needs to go through auth flow
    // When a message with the data 'refresh' is received, it reloads the current page.
    // When a message with the data 'window-open' is received an additional sign-in window will not be open since one is already open.
    useEffect(() => {
        const channel = new BroadcastChannel('refresh-channel');
        channel.onmessage = (event) => {
            switch (event.data) {
                case 'refresh':
                    location.reload();
                    break;
                case 'window-open':
                    messageReceived = true;
                    break;
            }
        };
        return () => {
            channel.close();
        };
    }, []);

    useEffect(() => {
        window.onerror = handleAppErrors;
    }, []);

    // the useEffect hook is used to load the user data and feature flags when the app is first loaded only
    useEffect(() => {
        if (!isAuthenticated) {
            loadUserData();
        }
    }, [isAuthenticated, loadUserData]);

    const handleError = (error: Error, info: ErrorInfo) => {
        loggingClient.logError('App.tsx', 'handleError', error, { componentStack: info.componentStack });
    };

    if (!isAuthenticated) {
        return null;
    }
    return (
        <BrowserRouter basename={createUrlPath()}>
            <ErrorBoundary FallbackComponent={MainFallback} onError={handleError}>
                <div id="control-main-height" />
                <div className="main" style={{ height: `calc(100vh - ${getUrlBarHeight()}px)` }}>
                    {!inIframe && (
                        <>
                            <HamburgerMenu />
                            <LeftPanelContainer />
                        </>
                    )}

                    <div className="main-content">
                        {!inIframe && <Header />}
                        <Main />
                    </div>
                </div>
                {/* This is necessary to render the Tooltip component outside of its parent */}
                <div id={TOOLTIP_ROOT_ID} />
            </ErrorBoundary>
        </BrowserRouter>
    );
};

export default App;
