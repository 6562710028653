import { Column } from '@smartsheet/core-views-api-models';
import { AxiosError } from 'axios';
import { Filter, Pagination, ReportJobParams, ReportJobResponse } from '../../../common/interfaces/report';
import { GridRow } from '../../../components/Grid/Grid.interface';
import { ActionsUnion, createAction } from '../../../store';

export enum ActionTypes {
    RESET_REPORT_STATE = 'RESET_REPORT_STATE',

    // Report job actions
    LOAD_REPORT_JOB = 'LOAD_REPORT_JOB',
    CANCEL_LOAD_REPORT_JOB = 'CANCEL_LOAD_REPORT_JOB',
    STORE_REPORT_JOB = 'STORE_REPORT_JOB',
    FETCHING_ERROR_REPORT_JOB = 'FETCHING_ERROR_REPORT_JOB',
    POLL_REPORT_JOB = 'POLL_REPORT_JOB',
    CANCEL_POLL_REPORT_JOB = 'CANCEL_POLL_REPORT_JOB',

    // Reports paginated data actions
    FETCH_REPORT_DATA = 'FETCH_REPORT_DATA',
    CANCEL_FETCH_REPORT_DATA = 'CANCEL_FETCH_REPORT_DATA',
    STORE_REPORT_DATA = 'STORE_REPORT_DATA',
    FETCHING_ERROR_REPORT_DATA = 'FETCHING_ERROR_REPORT_DATA',

    // Report Columns
    FETCH_REPORT_COLUMNS = 'FETCH_REPORT_COLUMNS',
    CANCEL_FETCH_REPORT_COLUMNS = 'CANCEL_FETCH_REPORT_COLUMNS',
    STORE_REPORT_COLUMNS = 'STORE_REPORT_COLUMNS',
    FETCHING_ERROR_REPORT_COLUMNS = 'FETCHING_ERROR_REPORT_COLUMNS',
}

export const Actions = {
    // Action to reset view report state
    resetReportState: () => createAction(ActionTypes.RESET_REPORT_STATE),

    // Report job actions
    loadReportJob: (params: ReportJobParams) => createAction(ActionTypes.LOAD_REPORT_JOB, params),
    cancelLoadReportJob: () => createAction(ActionTypes.CANCEL_LOAD_REPORT_JOB),
    storeReportJob: (response: ReportJobResponse) => createAction(ActionTypes.STORE_REPORT_JOB, response),
    fetchingErrorReportJob: (error: AxiosError | Error) => createAction(ActionTypes.FETCHING_ERROR_REPORT_JOB, { error }),
    pollReportJob: (viewId: string) => createAction(ActionTypes.POLL_REPORT_JOB, { viewId }),
    cancelPollReportJob: () => createAction(ActionTypes.CANCEL_POLL_REPORT_JOB),

    // Report data actions
    fetchReportData: (params: { viewId: string; filter?: Filter; timestamp?: number; pagination?: Pagination; page: number }) =>
        createAction(ActionTypes.FETCH_REPORT_DATA, params),
    cancelFetchReportData: () => createAction(ActionTypes.CANCEL_FETCH_REPORT_DATA),
    storeReportData: (params: { rows: GridRow[]; pagination: Pagination }) => createAction(ActionTypes.STORE_REPORT_DATA, params),
    fetchingErrorReportData: (error: AxiosError | Error) => createAction(ActionTypes.FETCHING_ERROR_REPORT_DATA, { error }),

    // Report Columns actions
    fetchReportColumns: (viewId: string) => createAction(ActionTypes.FETCH_REPORT_COLUMNS, viewId),
    cancelFetchReportColumns: () => createAction(ActionTypes.CANCEL_FETCH_REPORT_COLUMNS),
    storeReportColumns: (response: Column[]) => createAction(ActionTypes.STORE_REPORT_COLUMNS, response),
    fetchingErrorReportColumns: (error: AxiosError | Error) => createAction(ActionTypes.FETCHING_ERROR_REPORT_COLUMNS, { error }),
};

export type Actions = ActionsUnion<typeof Actions>;
