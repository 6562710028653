import css from '@emotion/css';
import styled from '@emotion/styled';
import { SelectV2 } from '@smartsheet/lodestar-core';
import { FALLBACK_I18N_LOCALE } from '../../common/enums';
import { PicklistItem } from '../../common/interfaces';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useResizeHeight } from '../../common/hooks/useResizeHeight';
import { shortLocaleSelector } from '../../containers/Auth/Selectors';
import { INIT_HEIGHT, Z_INDEX_SELECT, calculateMaxMenuHeight } from './formFieldUtils';

export interface Props {
    dataClientId: string;
    inputIndex: number;
    options?: PicklistItem[];
    selectedOption: PicklistItem;
    placeholder?: string;
    onChange?: (value: unknown) => void;
    readOnly?: boolean;
    validation?: boolean;
    isSettingsMode: boolean;
    detailsDataRef?: React.RefObject<HTMLElement>;
}

const FormFieldPicklist = ({
    dataClientId,
    inputIndex,
    options,
    selectedOption,
    placeholder,
    onChange,
    readOnly,
    validation,
    isSettingsMode,
    detailsDataRef,
}: Props) => {
    const locale = useSelector(shortLocaleSelector) ?? FALLBACK_I18N_LOCALE;
    const updatedOptions = options || [];

    const [maxMenuHeight, setMaxMenuHeight] = React.useState<number>(INIT_HEIGHT);

    const resizeHeight = (height: number) => {
        setMaxMenuHeight(calculateMaxMenuHeight(height));
    };

    useResizeHeight({ resizeHeightCallback: resizeHeight, targetRef: detailsDataRef });

    const handleChange = (changedOption: PicklistItem | null) => {
        const newUpdatedOption = changedOption ? { value: changedOption.value || '', label: changedOption.label || '' } : { value: '', label: '' };
        if (onChange) {
            onChange(newUpdatedOption.value);
        }
    };

    const handleGetOptionKey = (option: PicklistItem) => {
        return option.label ? option.label : '';
    };

    const handleCreateOption = (newOption: string) => {
        if (onChange) {
            const newSelectedOption = {
                value: newOption,
                label: newOption,
            };
            handleChange(newSelectedOption);
        }
    };

    // Needed to use it to prevent Add {value} displayed when validation is enabled
    const onCreateOption = validation ? undefined : handleCreateOption;

    return (
        <StyledSelectV2
            id={`pli-${inputIndex}`}
            clientId={dataClientId}
            isSearchable={!isSettingsMode}
            isReadonly={readOnly}
            isClearable
            highlightFirstOptionAutomatically
            placeholder={placeholder}
            getOptionKey={handleGetOptionKey}
            options={updatedOptions}
            selectedOption={selectedOption}
            onChange={handleChange}
            onCreateOption={onCreateOption}
            locale={locale}
            portalProps={Z_INDEX_SELECT}
            isSettingsMode={isSettingsMode}
            useFuzzySearch
            maxMenuHeight={maxMenuHeight}
        />
    );
};

export default FormFieldPicklist;

// Needed to void the interaction with SelectV2 in the Details Panel Layout settings
const isSettingsModeCss = (props: { isSettingsMode: boolean }) => css`
    pointer-events: ${props.isSettingsMode ? 'none' : 'auto'};
`;

const StyledSelectV2 = styled(SelectV2)`
    ${isSettingsModeCss}
`;
