import styled from '@emotion/styled';
import { colors } from '@smartsheet/lodestar-core';
import * as React from 'react';
import { dateTimeFormat } from '../../../../common/DateTimeFormat';
import { TimeZone } from '../../../../common/enums';
import { AutomationIds, AutomationTypes } from '../../../../common/enums/AutomationElements.enum';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { getMimeTypeCssClassName } from './AttachmentUtils';
import './Attachment.css';

export interface AttachmentItemProps {
    name?: string;
    mimeType: string;
    createdBy?: string;
    sizeInKb?: number;
    createdAt: string;
    userLocale?: string;
    userTimeZone?: TimeZone;
    onClick: () => void;
}

const AttachmentItem: React.FC<AttachmentItemProps> = (props: AttachmentItemProps) => {
    const languageElements = useLanguageElements();

    const cssClassName = getMimeTypeCssClassName(props.mimeType);

    const createdByStamp = dateTimeFormat(props.userLocale, props.userTimeZone).format(new Date(props.createdAt));
    const createdByName = props.createdBy ? `${languageElements.DETAIL_ATTACHMENTS_BY}${props.createdBy ?? ''}` : '';
    const sizeInKb = props.sizeInKb ? `(${props.sizeInKb}k)` : '';

    return (
        <AttachmentItemContainer data-client-type={AutomationTypes.ATTACHMENT_ITEM_TYPE}>
            <AttachmentIconWrapper data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_ICON} className={cssClassName} />
            <AttachmentDetails>
                <AttachmentLink
                    data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_NAME}
                    target="_self"
                    rel="noreferrer"
                    onClick={() => props.onClick()}
                    title={props.name}
                >
                    {props.name}
                    <AttachmentCreatedDetails data-client-id={AutomationIds.ATTACHMENT_ITEM_ATTACHMENT_INFO}>
                        <small>{`${createdByStamp}${createdByName} ${sizeInKb}`}</small>
                    </AttachmentCreatedDetails>
                </AttachmentLink>
            </AttachmentDetails>
        </AttachmentItemContainer>
    );
};

export default AttachmentItem;

const AttachmentItemContainer = styled.div`
    overflow: hidden;
    line-height: 20px;
    border-radius: 2px;
    height: 33px;
    padding: 10px 20px 7px 20px;
    cursor: pointer;

    :hover {
        background-color: ${colors.visBlueLight30};
    }
`;

const AttachmentIconWrapper = styled.div`
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
`;

const AttachmentDetails = styled.div`
    float: left;
    width: calc(100% - 80px);
    overflow: hidden;
`;

const AttachmentLink = styled.a`
    text-decoration: none;
    color: ${colors.neutralDark30};
    font-weight: bold;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AttachmentCreatedDetails = styled.div`
    line-height: 12px;
    opacity: 0.6;
`;
