/**
 * On Mobile, sometimes the address bar is shown, sometimes the address bar is hidden.
 * We need to calculate the height and make sure the view is low enough that the DV header doesn't cover up the view title.
 * Note the following:
 * - the root element is set to 100vh
 * - 'vh' is interpreted differently on mobile devices (it includes the url bar, unlike desktop).
 * - we call this from various parts of the app instead of just at load time, because the user may swipe up/down
 *   on their url bar & alter the size at any time
 */
export const getUrlBarHeight = (): number => {
    const actualHeight = window.innerHeight;
    const controlHeightElement = document.getElementById('root');
    const elementHeight = controlHeightElement ? controlHeightElement.clientHeight : 0;
    const urlBarHeight = elementHeight - actualHeight;
    return Math.max(urlBarHeight, 0);
};
