// Issue: Using import here works for our app but not for our tests.
// The test fails to find the svg content & returns undef for all svgs.

// Assumption: We're assuming that the ORDER of elements in the column.options arrays that we get from
// SMAR is the same for all languages, i.e. first element for DECISION_SHAPES in English is 'Yes'
// and for German it's 'Ja', and they both will map to the DecisionShapeYes svg import below.
// Note that for foreign languages, the SymbolSetName
// is STILL in English (see matching enum below) but the column.options arrays
// contain foreign language values

import ArrowEast from '../../assets/images/symbolIcons/ArrowEast.svg';
import ArrowNorth from '../../assets/images/symbolIcons/ArrowNorth.svg';
import ArrowNorthEast from '../../assets/images/symbolIcons/ArrowNorthEast.svg';
import ArrowSouth from '../../assets/images/symbolIcons/ArrowSouth.svg';
import ArrowSouthEast from '../../assets/images/symbolIcons/ArrowSouthEast.svg';
import Blue from '../../assets/images/symbolIcons/Blue.svg';
import Checked from '../../assets/images/symbolIcons/Checked.svg';
import DecisionShapeHold from '../../assets/images/symbolIcons/DecisionShapeHold.svg';
import DecisionShapeNo from '../../assets/images/symbolIcons/DecisionShapeNo.svg';
import DecisionShapeYes from '../../assets/images/symbolIcons/DecisionShapeYes.svg';
import DecisionSymbolHold from '../../assets/images/symbolIcons/DecisionSymbolHold.svg';
import DecisionSymbolNo from '../../assets/images/symbolIcons/DecisionSymbolNo.svg';
import DecisionSymbolYes from '../../assets/images/symbolIcons/DecisionSymbolYes.svg';
import Direction3Down from '../../assets/images/symbolIcons/Direction3Down.svg';
import Direction3Unchanged from '../../assets/images/symbolIcons/Direction3Unchanged.svg';
import Direction3Up from '../../assets/images/symbolIcons/Direction3Up.svg';
import Direction4Down from '../../assets/images/symbolIcons/Direction4Down.svg';
import Direction4Left from '../../assets/images/symbolIcons/Direction4Left.svg';
import Direction4Right from '../../assets/images/symbolIcons/Direction4Right.svg';
import Direction4Up from '../../assets/images/symbolIcons/Direction4Up.svg';
import Effort_0 from '../../assets/images/symbolIcons/Effort_0.svg';
import Effort_1 from '../../assets/images/symbolIcons/Effort_1.svg';
import Effort_2 from '../../assets/images/symbolIcons/Effort_2.svg';
import Effort_3 from '../../assets/images/symbolIcons/Effort_3.svg';
import Effort_4 from '../../assets/images/symbolIcons/Effort_4.svg';
import Effort_5 from '../../assets/images/symbolIcons/Effort_5.svg';
import FlagOff from '../../assets/images/symbolIcons/FlagOff.svg';
import FlagOn from '../../assets/images/symbolIcons/FlagOn.svg';
import Gray from '../../assets/images/symbolIcons/Gray.svg';
import Green from '../../assets/images/symbolIcons/Green.svg';
import Harvey_0 from '../../assets/images/symbolIcons/Harvey_0.svg';
import Harvey_100 from '../../assets/images/symbolIcons/Harvey_100.svg';
import Harvey_25 from '../../assets/images/symbolIcons/Harvey_25.svg';
import Harvey_50 from '../../assets/images/symbolIcons/Harvey_50.svg';
import Harvey_75 from '../../assets/images/symbolIcons/Harvey_75.svg';
import Hearts_0 from '../../assets/images/symbolIcons/Hearts_0.svg';
import Hearts_1 from '../../assets/images/symbolIcons/Hearts_1.svg';
import Hearts_2 from '../../assets/images/symbolIcons/Hearts_2.svg';
import Hearts_3 from '../../assets/images/symbolIcons/Hearts_3.svg';
import Hearts_4 from '../../assets/images/symbolIcons/Hearts_4.svg';
import Hearts_5 from '../../assets/images/symbolIcons/Hearts_5.svg';
import Money_0 from '../../assets/images/symbolIcons/Money_0.svg';
import Money_1 from '../../assets/images/symbolIcons/Money_1.svg';
import Money_2 from '../../assets/images/symbolIcons/Money_2.svg';
import Money_3 from '../../assets/images/symbolIcons/Money_3.svg';
import Money_4 from '../../assets/images/symbolIcons/Money_4.svg';
import Money_5 from '../../assets/images/symbolIcons/Money_5.svg';
import Pain_0 from '../../assets/images/symbolIcons/Pain_0.svg';
import Pain_1 from '../../assets/images/symbolIcons/Pain_1.svg';
import Pain_2 from '../../assets/images/symbolIcons/Pain_2.svg';
import Pain_3 from '../../assets/images/symbolIcons/Pain_3.svg';
import Pain_4 from '../../assets/images/symbolIcons/Pain_4.svg';
import Pain_5 from '../../assets/images/symbolIcons/Pain_5.svg';
import PriorityHigh from '../../assets/images/symbolIcons/PriorityHigh.svg';
import PriorityLow from '../../assets/images/symbolIcons/PriorityLow.svg';
import PriorityMedium from '../../assets/images/symbolIcons/PriorityMedium.svg';
import Progress_0 from '../../assets/images/symbolIcons/Progress_0.svg';
import Progress_100 from '../../assets/images/symbolIcons/Progress_100.svg';
import Progress_25 from '../../assets/images/symbolIcons/Progress_25.svg';
import Progress_50 from '../../assets/images/symbolIcons/Progress_50.svg';
import Progress_75 from '../../assets/images/symbolIcons/Progress_75.svg';
import Red from '../../assets/images/symbolIcons/Red.svg';
import Signal_0 from '../../assets/images/symbolIcons/Signal_0.svg';
import Signal_1 from '../../assets/images/symbolIcons/Signal_1.svg';
import Signal_2 from '../../assets/images/symbolIcons/Signal_2.svg';
import Signal_3 from '../../assets/images/symbolIcons/Signal_3.svg';
import Signal_5 from '../../assets/images/symbolIcons/Signal_5.svg';
import SkiEasy from '../../assets/images/symbolIcons/SkiEasy.svg';
import SkiExpertsOnly from '../../assets/images/symbolIcons/SkiExpertsOnly.svg';
import SkiHard from '../../assets/images/symbolIcons/SkiHard.svg';
import SkiIntermediate from '../../assets/images/symbolIcons/SkiIntermediate.svg';
import StarOff from '../../assets/images/symbolIcons/StarOff.svg';
import StarOn from '../../assets/images/symbolIcons/StarOn.svg';
import Stars_0 from '../../assets/images/symbolIcons/Stars_0.svg';
import Stars_1 from '../../assets/images/symbolIcons/Stars_1.svg';
import Stars_2 from '../../assets/images/symbolIcons/Stars_2.svg';
import Stars_3 from '../../assets/images/symbolIcons/Stars_3.svg';
import Stars_4 from '../../assets/images/symbolIcons/Stars_4.svg';
import Stars_5 from '../../assets/images/symbolIcons/Stars_5.svg';
import Unchecked from '../../assets/images/symbolIcons/Unchecked.svg';
import VcrFastForward from '../../assets/images/symbolIcons/VcrFastForward.svg';
import VcrPause from '../../assets/images/symbolIcons/VcrPause.svg';
import VcrPlay from '../../assets/images/symbolIcons/VcrPlay.svg';
import VcrRewind from '../../assets/images/symbolIcons/VcrRewind.svg';
import VcrStop from '../../assets/images/symbolIcons/VcrStop.svg';
import WeatherCloudy from '../../assets/images/symbolIcons/WeatherCloudy.svg';
import WeatherPartlySunny from '../../assets/images/symbolIcons/WeatherPartlySunny.svg';
import WeatherRainy from '../../assets/images/symbolIcons/WeatherRainy.svg';
import WeatherStormy from '../../assets/images/symbolIcons/WeatherStormy.svg';
import WeatherSunny from '../../assets/images/symbolIcons/WeatherSunny.svg';
import Yellow from '../../assets/images/symbolIcons/Yellow.svg';
import { ColumnType, SymbolSetName } from '../../common/enums';
import { LanguageElements } from '../../language-elements/LanguageElements';

export const CheckboxOptions: string[] = ['false', 'true'];

const SymbolLookup = new Map([
    [
        SymbolSetName.NONE,
        [
            // Special case accounts for CHECKBOX type when there is no symbol associated with the column
            Unchecked,
            Checked,
        ],
    ],
    [
        SymbolSetName.FLAG,
        [
            // Special case for CHECKBOX type when assoc. symol is FLAG
            FlagOff,
            FlagOn,
        ],
    ],
    [
        SymbolSetName.STAR,
        [
            // Special case for CHECKBOX type when assoc. symol is STAR
            StarOff,
            StarOn,
        ],
    ],
    [
        SymbolSetName.PRIORITY,
        [
            // ALL other cases below relate to PICKLIST column types with specific symbol
            PriorityHigh,
            PriorityLow,
        ],
    ],
    [SymbolSetName.PRIORITY_HML, [PriorityHigh, PriorityMedium, PriorityLow]],
    [SymbolSetName.DECISION_SYMBOLS, [DecisionSymbolYes, DecisionSymbolHold, DecisionSymbolNo]],
    [SymbolSetName.DECISION_SHAPES, [DecisionShapeYes, DecisionShapeHold, DecisionShapeNo]],
    [SymbolSetName.VCR, [VcrStop, VcrRewind, VcrPlay, VcrFastForward, VcrPause]],
    [SymbolSetName.RYG, [Red, Yellow, Green]],
    [SymbolSetName.RYGB, [Red, Yellow, Green, Blue]],
    [SymbolSetName.RYGG, [Red, Yellow, Green, Gray]],
    [SymbolSetName.WEATHER, [WeatherSunny, WeatherPartlySunny, WeatherCloudy, WeatherRainy, WeatherStormy]],
    [SymbolSetName.HARVEY_BALLS, [Harvey_0, Harvey_25, Harvey_50, Harvey_75, Harvey_100]],
    [SymbolSetName.PROGRESS, [Progress_0, Progress_25, Progress_50, Progress_75, Progress_100]],
    [SymbolSetName.ARROWS_3_WAY, [ArrowSouth, ArrowEast, ArrowNorth]],
    [SymbolSetName.ARROWS_4_WAY, [ArrowSouth, ArrowSouthEast, ArrowNorthEast, ArrowNorth]],
    [SymbolSetName.ARROWS_5_WAY, [ArrowSouth, ArrowSouthEast, ArrowEast, ArrowNorthEast, ArrowNorth]],
    [SymbolSetName.DIRECTIONS_3_WAY, [Direction3Up, Direction3Unchanged, Direction3Down]],
    [SymbolSetName.DIRECTIONS_4_WAY, [Direction4Down, Direction4Right, Direction4Up, Direction4Left]],
    [SymbolSetName.SKI, [SkiEasy, SkiIntermediate, SkiHard, SkiExpertsOnly]],
    [SymbolSetName.SIGNAL, [Signal_0, Signal_1, Signal_2, Signal_3, Signal_5]],
    [SymbolSetName.STAR_RATING, [Stars_0, Stars_1, Stars_2, Stars_3, Stars_4, Stars_5]],
    [SymbolSetName.HEARTS, [Hearts_0, Hearts_1, Hearts_2, Hearts_3, Hearts_4, Hearts_5]],
    [SymbolSetName.MONEY, [Money_0, Money_1, Money_2, Money_3, Money_4, Money_5]],
    [SymbolSetName.EFFORT, [Effort_0, Effort_1, Effort_2, Effort_3, Effort_4, Effort_5]],
    [SymbolSetName.PAIN, [Pain_0, Pain_1, Pain_2, Pain_3, Pain_4, Pain_5]],
]);

export const createSymbolLookupOptionsMap = (languageElements: LanguageElements): Map<SymbolSetName, string[]> => {
    return new Map<SymbolSetName, string[]>([
        [SymbolSetName.PRIORITY, [languageElements.Symbol_High, languageElements.Symbol_Low]],
        [SymbolSetName.PRIORITY_HML, [languageElements.Symbol_High, languageElements.Symbol_Medium, languageElements.Symbol_Low]],
        [SymbolSetName.DECISION_SYMBOLS, [languageElements.Symbol_Yes, languageElements.Symbol_Hold, languageElements.Symbol_No]],
        [SymbolSetName.DECISION_SHAPES, [languageElements.Symbol_Yes, languageElements.Symbol_Hold, languageElements.Symbol_No]],
        [
            SymbolSetName.VCR,
            [
                languageElements.Symbol_Stop,
                languageElements.Symbol_Rewind,
                languageElements.Symbol_Play,
                languageElements.Symbol_Fast_Forward,
                languageElements.Symbol_Pause,
            ],
        ],
        [SymbolSetName.RYG, [languageElements.Symbol_Red, languageElements.Symbol_Yellow, languageElements.Symbol_Green]],
        [
            SymbolSetName.RYGB,
            [languageElements.Symbol_Red, languageElements.Symbol_Yellow, languageElements.Symbol_Green, languageElements.Symbol_Blue],
        ],
        [
            SymbolSetName.RYGG,
            [languageElements.Symbol_Red, languageElements.Symbol_Yellow, languageElements.Symbol_Green, languageElements.Symbol_Gray],
        ],
        [
            SymbolSetName.WEATHER,
            [
                languageElements.Symbol_Sunny,
                languageElements.Symbol_Partly_Sunny,
                languageElements.Symbol_Cloudy,
                languageElements.Symbol_Rainy,
                languageElements.Symbol_Stormy,
            ],
        ],
        [
            SymbolSetName.HARVEY_BALLS,
            [
                languageElements.Symbol_Empty,
                languageElements.Symbol_Quarter,
                languageElements.Symbol_Half,
                languageElements.Symbol_Three_Quarter,
                languageElements.Symbol_Full,
            ],
        ],
        [
            SymbolSetName.PROGRESS,
            [
                languageElements.Symbol_Empty,
                languageElements.Symbol_Quarter,
                languageElements.Symbol_Half,
                languageElements.Symbol_Three_Quarter,
                languageElements.Symbol_Full,
            ],
        ],
        [SymbolSetName.ARROWS_3_WAY, [languageElements.Symbol_Down, languageElements.Symbol_Sideways, languageElements.Symbol_Up]],
        [
            SymbolSetName.ARROWS_4_WAY,
            [languageElements.Symbol_Down, languageElements.Symbol_Angle_Up, languageElements.Symbol_Angle_Down, languageElements.Symbol_Up],
        ],
        [
            SymbolSetName.ARROWS_5_WAY,
            [
                languageElements.Symbol_Down,
                languageElements.Symbol_Angle_Up,
                languageElements.Symbol_Sideways,
                languageElements.Symbol_Angle_Down,
                languageElements.Symbol_Up,
            ],
        ],
        [SymbolSetName.DIRECTIONS_3_WAY, [languageElements.Symbol_Up, languageElements.Symbol_Unchanged, languageElements.Symbol_Down]],
        [
            SymbolSetName.DIRECTIONS_4_WAY,
            [languageElements.Symbol_Down, languageElements.Symbol_Right, languageElements.Symbol_Up, languageElements.Symbol_Left],
        ],
        [
            SymbolSetName.SKI,
            [
                languageElements.Symbol_Easy,
                languageElements.Symbol_Intermediate,
                languageElements.Symbol_Advanced,
                languageElements.Symbol_Experts_Only,
            ],
        ],
        [
            SymbolSetName.SIGNAL,
            [
                languageElements.Symbol_Empty,
                languageElements.Symbol_Quarter,
                languageElements.Symbol_Half,
                languageElements.Symbol_Three_Quarter,
                languageElements.Symbol_Full,
            ],
        ],
        [
            SymbolSetName.STAR_RATING,
            [
                languageElements.Symbol_Zero,
                languageElements.Symbol_One,
                languageElements.Symbol_Two,
                languageElements.Symbol_Three,
                languageElements.Symbol_Four,
                languageElements.Symbol_Five,
            ],
        ],
        [
            SymbolSetName.HEARTS,
            [
                languageElements.Symbol_Zero,
                languageElements.Symbol_One,
                languageElements.Symbol_Two,
                languageElements.Symbol_Three,
                languageElements.Symbol_Four,
                languageElements.Symbol_Five,
            ],
        ],
        [
            SymbolSetName.MONEY,
            [
                languageElements.Symbol_Zero,
                languageElements.Symbol_One,
                languageElements.Symbol_Two,
                languageElements.Symbol_Three,
                languageElements.Symbol_Four,
                languageElements.Symbol_Five,
            ],
        ],
        [
            SymbolSetName.EFFORT,
            [
                languageElements.Symbol_Zero,
                languageElements.Symbol_One,
                languageElements.Symbol_Two,
                languageElements.Symbol_Three,
                languageElements.Symbol_Four,
                languageElements.Symbol_Five,
            ],
        ],
        [
            SymbolSetName.PAIN,
            [
                languageElements.Symbol_No_Pain,
                languageElements.Symbol_Mild,
                languageElements.Symbol_Moderate,
                languageElements.Symbol_Severe,
                languageElements.Symbol_Very_Severe,
                languageElements.Symbol_Extreme,
            ],
        ],
    ]);
};

/**
 * Iterate the symbolOptionsMap to create a new map with the key being the SymbolSetName + string value of the symbol.
 * The value will be the index of the value array string
 *
 * @param languageElements
 */
export const createSymbolOptionsIndexMap = (languageElements: LanguageElements) => {
    const symbolOptionsMap = createSymbolLookupOptionsMap(languageElements);

    const symbolOptionsIndexMap = new Map<string, number>();
    symbolOptionsMap.forEach((value, key) => {
        value.forEach((symbol, index) => {
            symbolOptionsIndexMap.set(`${key}|${symbol}`, index);
        });
    });

    return symbolOptionsIndexMap;
};

export const getPickListOptions = (languageElements: LanguageElements, options?: string[], symbolName?: SymbolSetName): string[] => {
    if (options) {
        return options;
    }

    const symbolLookupOptionsMap = createSymbolLookupOptionsMap(languageElements);
    if (symbolName && symbolLookupOptionsMap.has(symbolName)) {
        return symbolLookupOptionsMap.get(symbolName) as string[];
    }

    return [];
};

export const SymbolImageUrlResolver = (symbolName: SymbolSetName | undefined, index: number) => {
    if (symbolName != null && SymbolLookup.get(symbolName) != null) {
        return SymbolLookup.get(symbolName)![index];
    } else {
        return;
    }
};

export const columnTypeToSymbolSetNameMap = new Map([
    // Picklist
    [ColumnType.RYG, SymbolSetName.RYG],
    [ColumnType.RYGB, SymbolSetName.RYGB],
    [ColumnType.RYGG, SymbolSetName.RYGG],
    [ColumnType.DECISIONSYMBOLS, SymbolSetName.DECISION_SYMBOLS],
    [ColumnType.DECISIONSHAPES, SymbolSetName.DECISION_SHAPES],
    [ColumnType.DECISIONVCR, SymbolSetName.VCR],
    [ColumnType.DIFFICULTY4, SymbolSetName.SKI],
    [ColumnType.PRIORITY, SymbolSetName.PRIORITY],
    [ColumnType.PRIORITYHML, SymbolSetName.PRIORITY_HML],
    [ColumnType.HARVEY, SymbolSetName.HARVEY_BALLS],
    [ColumnType.PROGRESS5, SymbolSetName.PROGRESS],
    [ColumnType.DIRECTIONS4, SymbolSetName.DIRECTIONS_4_WAY],
    [ColumnType.DIRECTIONS3, SymbolSetName.DIRECTIONS_3_WAY],
    [ColumnType.STAR6, SymbolSetName.STAR_RATING],
    [ColumnType.HEART6, SymbolSetName.HEARTS],
    [ColumnType.EFFORT6, SymbolSetName.EFFORT],
    [ColumnType.PAIN6, SymbolSetName.PAIN],
    [ColumnType.MONEY6, SymbolSetName.MONEY],
    [ColumnType.SIGNAL5, SymbolSetName.SIGNAL],
    [ColumnType.WEATHER, SymbolSetName.WEATHER],
    [ColumnType.ARROWS3, SymbolSetName.ARROWS_3_WAY],
    [ColumnType.ARROWS4, SymbolSetName.ARROWS_4_WAY],
    [ColumnType.ARROWS5, SymbolSetName.ARROWS_5_WAY],

    // Checkbox
    [ColumnType.STAR, SymbolSetName.STAR],
    [ColumnType.FLAG, SymbolSetName.FLAG],
]);
