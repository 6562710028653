import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AsyncStatus, ViewSourceType } from '../../common/enums';
import * as AppActions from '../App/Actions';
import { getViewIdFromRoute } from '../App/AppUtils';
import { dvReportsDecoupleFeatureSelector } from '../App/Selectors';
import * as ViewActions from './Actions';
import { viewConfigSelector } from './Selectors';
import ViewContainer from './ViewContainer';
import ViewContainerLegacy from './ViewContainerLegacy';

const ViewContainerWrapper = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const reportsDecoupleFeature = useSelector(dvReportsDecoupleFeatureSelector);
    const viewConfigResult = useSelector(viewConfigSelector);
    const viewId = getViewIdFromRoute(location);

    useEffect(() => {
        dispatch(ViewActions.Actions.resetViewState());
        return () => {
            dispatch(ViewActions.Actions.resetViewState());
        };
    }, [dispatch, viewId]);

    useEffect(() => {
        if (!viewId) {
            return;
        }
        if (viewConfigResult.status === AsyncStatus.NOT_STARTED) {
            dispatch(ViewActions.Actions.fetchConfig(viewId));
        }
    }, [dispatch, viewId, viewConfigResult.status]);

    if (!viewId) {
        return null;
    }
    if (viewConfigResult.status === AsyncStatus.ERROR) {
        dispatch(AppActions.Actions.setAppStageError(viewConfigResult.error));
        return null;
    } else if (viewConfigResult.status !== AsyncStatus.DONE) {
        return null;
    }

    // Render the new ViewContainer functional component if the DV_REPORTS_DECOUPLE feature flag is enabled & the view source type is a report
    // Otherwise, render the legacy ViewContainer class component. This is to ensure the existing flow is not impacted.
    if (reportsDecoupleFeature && viewConfigResult.data.viewSourceType === ViewSourceType.REPORT) {
        return <ViewContainer key={viewConfigResult.data.id} viewConfig={viewConfigResult} />;
    } else {
        return <ViewContainerLegacy viewId={viewId} viewConfig={viewConfigResult} />;
    }
};
export default ViewContainerWrapper;
