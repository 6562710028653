import * as Immutable from 'immutable';
import { List } from 'immutable';
import * as React from 'react';
import { SyntheticEvent } from 'react';
import { DraggableCore, DraggableData } from 'react-draggable';
import { connect } from 'react-redux';
import {
    ArrowKeyStepper,
    AutoSizer,
    CellMeasurerCache,
    Column as ColumnRV,
    IndexRange,
    OverscanIndexRange,
    ScrollIndices,
    SortDirection,
    SortDirectionType,
    SortIndicator,
    Table,
    TableCellProps,
    TableHeaderProps,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import iconFilter from '../../assets/images/icon-filter.svg';
import settingsIcon from '../../assets/images/icons/settings-white.svg';
import { MAIN_HEADER_HEIGHT, TOTAL_HEADER_HEIGHT } from '../../common/constants';
import { AccessLevel } from '../../common/enums';
import { AutomationIds, AutomationTypes } from '../../common/enums/AutomationElements.enum';
import { IFilterConditionUI, IFilterUI } from '../../common/interfaces';
import { getUrlBarHeight } from '../../common/utils';
import { ColumnWidthRepository } from '../../common/utils/ColumnWidthRepository';
import { ViewSource } from '../../common/utils/ViewSource';
import * as DetailSelectors from '../../containers/View/Details/Selectors';
import { StoreState } from '../../store';
import { BaseComponent } from '../Base';
import { Button, SettingsLinkButton } from '../Buttons';
import EmptyState, { EmptyStateProps } from '../EmptyState/EmptyState';
import { ErrorInfo } from '../EmptyState/ErrorInfo.interface';
import CellText from './Cells/CellText';
import { RowStatus } from './Cells/RowStatus';
import './Grid.css';
import { ColumnHeader, GridMouseEvent, RowData, RowGetter, SortConfig } from './Grid.interface';
import GridHeaderRow from './Rows/GridHeaderRow';
import GridRowElement from './Rows/GridRowElement';
import { sortRows } from './SortRows';

interface OwnProps {
    onShouldGridActionProceed?: (event?: Event | RowGetter) => boolean;
    onRowSelect: (event: Event | RowGetter) => void;
    closeDetailsPanel: () => void;
    selectedFilterIndex: number;
    viewSource: ViewSource | undefined;
    viewId: string;
    accessLevel: AccessLevel;
    wrapText: boolean;
    showFormats: boolean;
    errorInfo?: ErrorInfo;
    filterDisabled: boolean;
    filter: IFilterUI | undefined;
    onClickFilterButton: (dataKey: number) => void;
    inIframe: boolean;
    dataRows: RowData[];
    totalRows: number;
    onEscape: () => void;
    disableSetFocus: boolean;
    isLoadingData: boolean;
    isVisibleLoadingIndicator: boolean;
    onClickHeaderButton: () => void;
}

interface StateProps {
    selectedRowId?: string;
}

type Props = OwnProps & StateProps;

interface State {
    sortBy: string;
    sortDirection: SortDirectionType;
    sortedList: List<RowData> | undefined;
    scrollToRow: number;
    selectedRow: number;
    columnResizeInProgress: boolean;
    columnHeaderHeight: number;
    rowHeightCache: CellMeasurerCache | undefined;
    lastRenderedWidth: number;
    rowHeight: number;
    loadingTooltipVisible: boolean;
}

export const GRID_OUTER_WIDTH_PADDING = 35; // includes 15px for vert scroll bar, 10px padding on both sides of grid
export const GRID_COLUMN_PADDING = 10;
export const GRID_MIN_ROW_HEIGHT = 40;
const LOADING_ROW_INDICATOR: number = 50;

export class GridLegacy extends BaseComponent<Props> {
    public state: State;
    private overscanRowCount: number = 10;
    private disableHeader: boolean = false;
    private minRowHeight: number = GRID_MIN_ROW_HEIGHT;
    private startIndex: number = 0;
    private stopIndex: number = 0;
    private totalRows: number = 0;
    private columnHeaders: ColumnHeader[] = [];

    public constructor(props: Props) {
        super(props);
        const list = Immutable.List(props.dataRows);
        const sortBy = 'index';
        const sortDirection = SortDirection.ASC;
        const sortedList = sortRows(
            list,
            { sortBy, sortDirection },
            this.props.viewSource!.getColumnMap(),
            this.props.viewSource!.rowIdToReportColumnIdToSheetColumn
        );
        const columnHeaderHeight = this.minRowHeight;
        const rowHeightCache = this.getRowHeightCache();
        const lastRenderedWidth = 0;
        const rowHeight = this.minRowHeight;
        this.totalRows = this.totalRows = this.props.isLoadingData ? this.props.dataRows.length + LOADING_ROW_INDICATOR : this.props.dataRows.length;
        this.columnHeaders = this.props.viewSource!.columnHeaders ? this.props.viewSource!.columnHeaders.slice() : [];
        this.state = {
            sortBy,
            sortDirection,
            sortedList,
            scrollToRow: -1,
            selectedRow: -1,
            columnResizeInProgress: false,
            columnHeaderHeight,
            rowHeightCache,
            lastRenderedWidth,
            rowHeight,
            loadingTooltipVisible: false,
        };
    }

    // Note css file forces scroll bar to always display to avoid a gap.
    public render(): React.ReactNode {
        // Current header height will vary if a view is in an iframe
        const currentHeaderHeight = this.props.inIframe ? TOTAL_HEADER_HEIGHT - MAIN_HEADER_HEIGHT : TOTAL_HEADER_HEIGHT;
        // If there are no rows of data, set width to available screen width so that messages in EmptyState prop are centered
        // in visible window instead of total width of underlying sheet/report (which may extend way past window)
        const totalWidth = this.totalRows > 0 ? this.getTotalWidth(this.columnHeaders) : 0;
        const emptyStateProps = this.getEmptyStateProps(this.props.accessLevel, this.props.viewId, this.props.errorInfo);
        const gridClassName =
            `inner-grid ${this.props.wrapText && this.state.rowHeightCache ? 'wrap-text ' : ''}` + `${this.props.showFormats ? 'show-formats' : ''}`;

        // In-line styling used for height to solve mobile display issues related to vh
        const autoSizerStyle = {
            height: `calc(100vh - ${currentHeaderHeight + getUrlBarHeight()}px)`,
            width: totalWidth,
        };
        const headerClassName = `header-column ${this.props.isVisibleLoadingIndicator && this.props.isLoadingData ? 'disabled' : ''}`;

        return (
            <div data-client-id={AutomationIds.VIEW_TABLE} onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => this.handleOnKeyPress(event)}>
                <div id="control-height" />
                <ArrowKeyStepper
                    onScrollToChange={this.selectCell}
                    columnCount={1}
                    rowCount={this.totalRows}
                    mode={'cells'}
                    scrollToColumn={1}
                    scrollToRow={this.state.scrollToRow}
                >
                    {({ onSectionRendered, scrollToColumn }: { onSectionRendered: any; scrollToColumn: number }) => (
                        <AutoSizer className="autosizer" disableWidth={false} style={autoSizerStyle}>
                            {({ height, width }: { height: number; width: number }) => (
                                <Table
                                    deferredMeasurementCache={this.state.rowHeightCache}
                                    disableHeader={this.disableHeader}
                                    className="grid-table"
                                    gridClassName={gridClassName}
                                    headerClassName={headerClassName}
                                    headerHeight={this.state.columnHeaderHeight}
                                    headerRowRenderer={GridHeaderRow}
                                    height={height}
                                    noRowsRenderer={() => (
                                        <EmptyState
                                            header={emptyStateProps.header}
                                            message={emptyStateProps.message}
                                            applyError={emptyStateProps.applyError}
                                            displayButton={emptyStateProps.displayButton}
                                            buttonComponent={emptyStateProps.buttonComponent}
                                        />
                                    )}
                                    onRowClick={this.handleOnRowClick}
                                    columnCount={1}
                                    onSectionRendered={onSectionRendered}
                                    overscanRowCount={this.overscanRowCount}
                                    rowHeight={this.state.rowHeightCache ? this.state.rowHeightCache.rowHeight : this.state.rowHeight}
                                    rowGetter={(getter: RowGetter) => this.getRowData(this.state.sortedList!, getter.index)}
                                    rowClassName={this.getRowClassName}
                                    rowCount={this.totalRows}
                                    rowRenderer={GridRowElement}
                                    scrollToColumn={scrollToColumn}
                                    scrollToIndex={this.startIndex}
                                    sort={this.sort}
                                    sortBy={this.state.sortBy}
                                    sortDirection={this.state.sortDirection}
                                    width={width}
                                    onRowsRendered={(gridIndexes: IndexRange & OverscanIndexRange) => this.onRowsRendered(gridIndexes)}
                                    scrollToAlignment={'start'}
                                >
                                    {this.getColumnJSX(this.columnHeaders)}
                                </Table>
                            )}
                        </AutoSizer>
                    )}
                </ArrowKeyStepper>
            </div>
        );
    }

    public componentDidMount(): void {
        this.updateColumnHeaderHeight();
    }

    public componentDidUpdate(prevProps: Props): void {
        if (
            this.props.selectedFilterIndex !== prevProps.selectedFilterIndex ||
            this.props.wrapText !== prevProps.wrapText ||
            this.props.viewSource?.viewData !== prevProps.viewSource?.viewData ||
            this.props.dataRows !== prevProps.dataRows
        ) {
            const list = Immutable.List(this.props.dataRows);
            const sortBy = this.state.sortBy;
            const sortDirection = this.state.sortDirection;
            const sortedList = sortRows(
                list,
                { sortBy, sortDirection },
                this.props.viewSource!.getColumnMap(),
                this.props.viewSource!.rowIdToReportColumnIdToSheetColumn
            );
            const rowHeightCache = this.getRowHeightCache();
            this.totalRows = this.props.isLoadingData ? this.props.totalRows : this.props.dataRows.length;
            this.columnHeaders = this.props.viewSource!.columnHeaders ? this.props.viewSource!.columnHeaders.slice() : [];

            // recalculate selected row if possible
            const selectedRowIndex = sortedList?.findIndex((row) => row.id === this.props.selectedRowId);

            this.setState({
                sortedList,
                scrollToRow: -1,
                selectedRow: selectedRowIndex ?? -1,
                rowHeightCache,
            });
        }

        this.setFocusToGrid();
    }
    /**
     * Note the 'index' prop on this event is different from the 'index' prop on the rowInfo. The 'index'
     * prop here correlates to the current sorted data & the item # in the DOM. In contrast, the 'index' prop on the
     * rowInfo is based on the props.list data that's passed in before any sorting, and doesn't change. Use
     * the latter to get data for the details panel, but use the former to control row highlight & selection.
     */
    public readonly handleOnRowClick = (mouseEvent: GridMouseEvent): void => {
        this.updateRowSelectState(mouseEvent.rowData as RowData, mouseEvent.index);
    };
    public readonly handleOnKeyPress = (keyEvent: React.KeyboardEvent<HTMLDivElement>): void => {
        // If user pressed Enter or space bar in table after arrowing to a row, select it.
        // Note: Apply index to sortedList, not props.list, so that correct data is returned for any sort order.
        if (keyEvent.key === 'Enter' || keyEvent.key === ' ') {
            const index = this.state.scrollToRow;
            const rowInfo = this.getRowData(this.state.sortedList!, index);
            if (rowInfo) {
                this.updateRowSelectState(rowInfo, index);
            }
            keyEvent.preventDefault();
        }

        // If user pressed Escape when Filter menu is open, call handler in View/index to close the dropdown
        if (keyEvent.key === 'Escape') {
            this.props.onEscape();
        }
    };
    /**
     * When the user clicks a row, reset the arrowedToRow to be the same as selected row (so that it doesn't highlight another
     * row until user resumes using arrow key)
     */
    private readonly updateRowSelectState = (rowInfo: RowData, index: number): void => {
        if (!this.props.onShouldGridActionProceed || this.props.onShouldGridActionProceed(rowInfo)) {
            this.setState({ selectedRow: index, scrollToRow: index }, () => this.props.onRowSelect(rowInfo));
        }
    };

    private getRowData(list: List<RowData>, index: number): RowData | undefined {
        if (!list || list.size === 0 || index < 0 || index >= list.size) {
            return { id: '', cells: {}, index: -1, columnData: {} } as RowData;
        }
        return list.get(index) || undefined;
    }

    // After sort, reset selected and scrolled to rows to -1 (since previously selected/scrolledTo rows may not be in DOM any longer)
    private readonly sort = (config: SortConfig): void => {
        if (!this.state || this.state.columnResizeInProgress) {
            return;
        }

        if (!this.props.onShouldGridActionProceed || this.props.onShouldGridActionProceed()) {
            const sortedList = sortRows(
                this.state.sortedList,
                config,
                this.props.viewSource!.getColumnMap(),
                this.props.viewSource!.rowIdToReportColumnIdToSheetColumn
            );
            if (this.state.rowHeightCache) {
                this.state.rowHeightCache.clearAll();
            }
            this.setState(
                {
                    sortBy: config.sortBy,
                    sortDirection: config.sortDirection,
                    sortedList,
                    scrollToRow: -1,
                    selectedRow: -1,
                },
                () => this.props.closeDetailsPanel()
            );
        }
    };

    /**
     * Set focus on inner-grid so that arrowing & PgUp/Down will scroll arrowed-to row into view
     */
    private readonly setFocusToGrid = (): void => {
        // DVK-0097: Skip call to focus() when in an iFrame to stop the undesired behaviour of the parent window scrolling to the location of the
        // iFrame sourced to a DV view.
        if (this.props.inIframe) {
            return;
        }

        // If details panel is open, don't alter focus
        if (this.props.disableSetFocus) {
            return;
        }

        const focusElement = document.getElementsByClassName('inner-grid')[0] as HTMLElement;
        if (focusElement) {
            focusElement.focus();
        }
    };
    private readonly selectCell = ({ scrollToColumn, scrollToRow }: ScrollIndices) => {
        // Figure out if user has arrowed beyond the visible grid. If yes, adjust the startIndex
        if (scrollToRow >= this.stopIndex) {
            this.startIndex = this.startIndex + 1;
        } else if (scrollToRow < this.startIndex) {
            this.startIndex = this.startIndex - 1;
        }

        this.setFocusToGrid();
        this.setState({
            scrollToRow,
        });
    };

    private readonly getRowClassName = (getter: RowGetter): string => {
        let classNames: string;
        if (getter.index < 0) {
            classNames = 'header-row';
        } else if (this.state.selectedRow === getter.index) {
            classNames = 'data-row selected-row';
        } else if (this.state.scrollToRow === getter.index) {
            classNames = 'data-row highlight-row';
        } else {
            classNames = 'data-row';
        }
        return classNames;
    };

    // Get total px of all column widths; outer width padding accounts for space for scroll bar, left & right outer padding & extra padding column 1
    private getTotalWidth = (columnHeaders: ColumnHeader[]): number => {
        const sumColumnWidths = columnHeaders.reduce((accumulator: number, column: ColumnHeader) => {
            return accumulator + (column.width ?? 0) + GRID_COLUMN_PADDING;
        }, 0);
        return sumColumnWidths + GRID_OUTER_WIDTH_PADDING || 0;
    };

    // Use 'columnHeaders' array to get column widths and ids from SMAR api
    private getColumnJSX = (columnHeaders: ColumnHeader[]): JSX.Element[] => {
        const columnDataJSX: JSX.Element[] = [];

        const cellRenderFunc = (props: TableCellProps) => (
            <RowStatus tableCellProps={props} wrapText={this.props.wrapText} rowHeightCache={this.state.rowHeightCache} />
        );

        // Add column for saving & error status icons
        columnDataJSX.push(
            <ColumnRV
                key={'status-column'}
                width={36}
                minWidth={36}
                label={''}
                dataKey={'status-column'}
                cellRenderer={cellRenderFunc}
                className="outer-cell"
            />
        );

        // Add sheet/report columns
        for (const columnHeader of columnHeaders) {
            columnDataJSX.push(
                <ColumnRV
                    key={columnHeader.key}
                    width={columnHeader.width ?? 0}
                    minWidth={columnHeader.width}
                    label={columnHeader.title}
                    dataKey={columnHeader.key.toString()}
                    cellRenderer={(props) => (
                        <CellText tableCellProps={props} wrapText={this.props.wrapText} rowHeightCache={this.state.rowHeightCache} />
                    )}
                    headerRenderer={this.gridHeaderCells}
                    className="outer-cell"
                />
            );
        }

        return columnDataJSX;
    };

    private getEmptyStateProps = (accessLevel: AccessLevel, viewId: string, errorInfo?: ErrorInfo): EmptyStateProps => {
        if (!errorInfo) {
            return {};
        }

        return {
            header: errorInfo.header,
            message: errorInfo.message,
            applyError: true,
            displayButton: accessLevel === AccessLevel.OWNER,
            buttonComponent: (
                <SettingsLinkButton route={`/views/${viewId}/admin/basic`} controlId={AutomationIds.GRID_SETTINGS_BUTTON} image={settingsIcon} />
            ),
        };
    };

    private handleHeaderClick(event: React.MouseEvent, dataKey: string): void {
        if (this.props.isLoadingData) {
            event.stopPropagation();
            this.props.onClickHeaderButton();
        } else {
            this.sort({ sortBy: dataKey, sortDirection: this.state.sortDirection });
        }
    }

    private readonly gridHeaderCells = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps): JSX.Element[] => {
        const showSortIndicator = sortBy === dataKey;
        const showFilterIconForColumn = this.getShowFilterIconForColumn(dataKey);
        return [
            <React.Fragment key={dataKey}>
                <div
                    className="header-cell"
                    data-client-type={AutomationTypes.GRID_HEADER_CELL}
                    data-filter-applied={showFilterIconForColumn}
                    onClick={(e) => this.handleHeaderClick(e, dataKey)}
                >
                    <p
                        className="ReactVirtualized__Table__headerTruncatedText"
                        key="label"
                        title={label as string}
                        data-client-id={AutomationIds.VIEW_COLUMN_HEADER}
                    >
                        {label}
                    </p>
                    {showFilterIconForColumn && (
                        <Button
                            dataClientId={AutomationIds.GRID_HEADER_FILTER_BUTTON}
                            icon={iconFilter}
                            className={`btn-transparent header-filter-button ${showSortIndicator ? 'margin-right' : ''}`}
                            onClick={(e) => this.handleClickHeaderFilter(e, dataKey)}
                        />
                    )}
                    {showSortIndicator && (
                        <div className="sort-icon" data-client-id={AutomationIds.VIEW_COLUMN_SORT_ICON}>
                            <SortIndicator key="SortIndicator" sortDirection={sortDirection} />
                        </div>
                    )}
                    <DraggableCore
                        key={dataKey}
                        onStart={this.onStart}
                        onDrag={(event: MouseEvent, data) => this.onDrag(dataKey, event, data)}
                        onStop={(event: MouseEvent, data) => this.onStop(dataKey, event, data)}
                    >
                        <span className="DragHandleIcon">
                            <div className="vertical-pipe" />
                        </span>
                    </DraggableCore>
                </div>
            </React.Fragment>,
        ];
    };

    private onDrag = (dataKey: string, event: MouseEvent, data: DraggableData) => {
        const index = this.columnHeaders.findIndex((column) => column.key === dataKey);
        if (index !== -1) {
            this.columnHeaders[index].width = new ColumnWidthRepository().applyMinMaxToWidth(data.lastX + data.deltaX);
        }

        let lastRenderedWidth = this.state.lastRenderedWidth;
        const totalWidth = this.getTotalWidth(this.columnHeaders);
        if (this.state.rowHeightCache && this.state.lastRenderedWidth !== totalWidth) {
            lastRenderedWidth = totalWidth;
            this.state.rowHeightCache.clearAll();
        }

        this.setState({
            lastRenderedWidth,
        });
    };

    private isContainedInFilter = (dataKey: number): boolean => {
        return (
            Boolean(this.props.filter) &&
            this.props.filter!.conditions.some((condition: IFilterConditionUI) => parseInt(condition.columnId, 10) === dataKey)
        );
    };

    private handleClickHeaderFilter = (e: SyntheticEvent, dataKey: string): void => {
        e.stopPropagation();
        this.props.onClickFilterButton(Number(dataKey));
    };

    private onStart = (): void => {
        this.setState({ columnResizeInProgress: true });
    };

    private onStop = (columnId: string, event: any, data: DraggableData): void => {
        const viewId = this.props.viewSource!.viewData.viewId!;
        new ColumnWidthRepository().saveColumnWidths(viewId, columnId, data.lastX + data.deltaX);
        this.updateColumnHeaderHeight();

        // We need a timeout here so that we can ensure the state gets set after the other event code (specifically the sort click handler)
        setTimeout(() => this.setState({ columnResizeInProgress: false }), 1);
    };

    // React-virtualized requires the header height to be passed in as number to display the grid correctly
    // but we don't know the correct height initially because we wrap long column headers.
    // So here we get the height attribute for 'header-row' (which is set to auto height)
    // and update state.
    private updateColumnHeaderHeight(): void {
        const headerRow = document.getElementsByClassName('header-row')[0] as HTMLElement;
        const columnHeaderHeight = headerRow.offsetHeight;
        this.setState({ columnHeaderHeight });
    }

    private getRowHeightCache(): CellMeasurerCache | undefined {
        return this.props.wrapText
            ? new CellMeasurerCache({
                  fixedWidth: true,
                  minHeight: this.minRowHeight + 1,
              })
            : undefined;
    }

    // Keep track of the top and bottom rows that are currently visible. Needed for two reasons:
    // 1. keep track of the row that should display at top after rows are resized
    // 2. Use both when user arrows up/down through grid so that the startIndex can be offset when arrowed to row is out of visible area
    private onRowsRendered = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }): void => {
        this.startIndex = startIndex;
        this.stopIndex = stopIndex;
    };

    /**
     * Show filter icon in column headers if the filter is enabled & the current user can edit it.
     * All of the following need to be true:
     * 1) The column is one of the fields used in the applied filter conditions
     * 2) The filter is currently enabled
     * 3) The user is OWNER or ADMIN -- OR -- the filter.ownerUserId is not null.
     */
    private getShowFilterIconForColumn = (dataKey: string): boolean => {
        return (
            !this.props.filterDisabled &&
            this.isContainedInFilter(parseInt(dataKey, 10)) &&
            (this.props.accessLevel === AccessLevel.OWNER || this.props.accessLevel === AccessLevel.ADMIN || this.props.filter!.ownerUserId != null)
        );
    };
}

const mapState = (state: StoreState, props: Props): StateProps => ({
    selectedRowId: DetailSelectors.selectedRowIdSelector(state),
});

export default connect<StateProps>(mapState)(GridLegacy);
