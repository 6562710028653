import { Column } from '@smartsheet/core-views-api-models';
import { MAIN_HEADER_HEIGHT, TOTAL_HEADER_HEIGHT } from '../../common/constants';
import { getUrlBarHeight } from '../../common/utils';
import { GridRow } from './Grid.interface';

export const GRID_OUTER_WIDTH_PADDING = 35; // includes 15px for vert scroll bar, 10px padding on both sides of grid
export const GRID_COLUMN_PADDING = 10;
export const GRID_MIN_ROW_HEIGHT = 40;
export const GRID_OVERSCAN_ROW_COUNT = 10;

/**
 * Set focus on inner-grid so that arrowing & PgUp/Down will scroll arrowed-to row into view
 */
export const updateFocusToGrid = (inIframe: boolean, disableSetFocus: boolean): void => {
    // DVK-0097: Skip call to focus() when in an iFrame to stop the undesired behaviour of the parent window scrolling to the location of the
    // iFrame sourced to a DV view.
    if (inIframe) {
        return;
    }

    // If details panel is open, don't alter focus
    if (disableSetFocus) {
        return;
    }

    const focusElement = document.getElementsByClassName('inner-grid')[0] as HTMLElement;
    if (focusElement) {
        focusElement.focus();
    }
};

export const getRowData = (rows: GridRow[], index: number): GridRow => {
    if (!rows || rows.length === 0 || index < 0 || index >= rows.length) {
        // Return empty row if no data or index is out of bounds. The empty row will be displayed as a skeleton row.
        return { id: '' };
    }

    return rows[index];
};

// Get total px of all column widths; outer width padding accounts for space for scroll bar, left & right outer padding & extra padding column 1
const getTotalWidth = (totalRows: number, columns: Column[] | undefined): number => {
    if (totalRows <= 0 || !columns) {
        return 0;
    }
    const sumColumnWidths = columns.reduce((accumulator, column) => {
        return accumulator + (column.width ?? 0) + GRID_COLUMN_PADDING;
    }, 0);
    return sumColumnWidths + GRID_OUTER_WIDTH_PADDING || 0;
};

export const getAutoSizerHeight = (totalRows: number, columns: Column[] | undefined, inIframe: boolean) => {
    const totalWidth = getTotalWidth(totalRows, columns);

    // Current header height will vary if a view is in an iframe
    const currentHeaderHeight = inIframe ? TOTAL_HEADER_HEIGHT - MAIN_HEADER_HEIGHT : TOTAL_HEADER_HEIGHT;

    // In-line styling used for height to solve mobile display issues related to vh
    return {
        height: `calc(100vh - ${currentHeaderHeight + getUrlBarHeight()}px)`,
        width: totalWidth,
    };
};
